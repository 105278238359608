import * as yup from "yup";

export default yup.object({
    name: yup.string()
        .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
        .required('Workspace name is required'),
    openai_api_key: yup.string()
})

export const workspaceSchemaWithOpenAIKey = yup.object({
    name: yup.string()
        .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
        .required('Workspace name is required'),
    openai_api_key: yup.string()
        .test('is-not-empty', 'OpenAIKey must not be empty', (value) => value !== '')
        .required('OpenAIKey is required')
        .test('is-not-start-with-sk', 'Invalid OpenAIKey', (value) => value?.startsWith('sk-') || false)
})
