/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TaskState {
    QUEUED = 'queued',
    IN_PROGRESS = 'in_progress',
    FAILED_RETRY = 'failed_retry',
    FAILED = 'failed',
    COMPLETE = 'complete',
    CANCELED = 'canceled',
}
