import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  PlusIcon,
  SelectField,
  DeleteIcon,
  WrenchIcon,
  Tooltip,
  Dialog,
  Pane,
  Text,
  Heading,
  Button,
  Strong,
  TextInputField,
  Autocomplete,
  DuplicateIcon, Link, CodeIcon, toaster
} from "evergreen-ui";
import {useRootServices} from "../../services";
import {observer} from "mobx-react-lite";
import {getForm, useApiSchemaForms} from "../../forms";
import {CurlGenerator} from "curl-generator";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import * as yup from "yup";
import {API} from "../../client";
import {ClientAuthorizationDialog, OpenAISettingsPopover, WorkspaceAPIKeyDialog} from "../../dialogs";
import {useNavigate} from "react-router-dom";
import {limitToFirst, onValue, orderByKey, query, ref, startAfter} from "firebase/database";
import {database} from "../../utils/firebase";
import OpenAIAPIKeyDialog from "../../dialogs/openAIAPIKeyDialog";


const RateLimit: React.FC<{apiForms: any, type: 'user_limit'| 'workspace_limit'}> = ({apiForms, type}) => {
  return (
      <Pane display="flex" flexDirection="column">
        <Strong paddingBottom={5}>{type == 'user_limit'? 'User Limit': 'Workspace Limit'}</Strong>
        <Pane
            display="flex"
            flexDirection="row"
            border="default"
            padding={10}
            paddingBottom={0}
            borderRadius={5} gap={10}
        >
          <TextInputField
              name={`${type}.rate_limit`}
              type="number"
              label="Rate Limit"
              placeholder="Rate Limit"
              value={apiForms.values[type].rate_limit}
              onChange={apiForms.handleChange}
              validationMessage={(
                  apiForms.touched[type]?.rate_limit
                  && apiForms.errors[type]?.rate_limit
              )}
          />
          <TextInputField
              name={`${type}.rate_limit_period`}
              type="number"
              label="Rate Limit Period (sec)"
              placeholder="Rate Limit Period"
              value={apiForms.values[type].rate_limit_period}
              onChange={apiForms.handleChange}
              validationMessage={(
                  apiForms.touched[type]?.rate_limit_period
                  && apiForms.errors[type]?.rate_limit_period
              )}
          />
          <TextInputField
              name={`${type}.max_requests`}
              type="number"
              label="Max Requests"
              placeholder="Max Requests"
              value={apiForms.values[type].max_requests}
              onChange={apiForms.handleChange}
              validationMessage={(
                  apiForms.touched[type]?.max_requests
                  && apiForms.errors[type]?.max_requests
              )}
          />
        </Pane>
      </Pane>
  )
}


const APIPage = () => {
  const navigate = useNavigate();

  const [saveTemplateDialog, setSaveTemplateDialog] = useState<boolean>();
  const {
    apiService,
    templateService,
    modelsService,
  } = useRootServices()
  const [ selectedAPI , setSelectedAPI] = useState<API | undefined>(undefined)

  const [deleteItem, setDeleteItem] = useState<string | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>("");

  const apiForms = useApiSchemaForms({
    onSubmit: (values) => {
      const {name, template_id, model_id, id} = values
      apiService.upsert(name, template_id, model_id, id).then(()=>{
        setSaveTemplateDialog(false)
      })
    },
  })

  const templateOptions = templateService.templatesArray
      .map(template => ({...template, toString: () => template.name}))

  const modelOptions = modelsService.allBaseModels
      .map(models => ({...models, toString: () => models.name}))

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="right">
        <Pane marginLeft={20}>
          <Button
            appearance="primary"
            iconAfter={PlusIcon}
            onClick={() => {
              apiForms.resetForm()
              setSaveTemplateDialog(true)
            }}
          >
            New API
          </Button>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        padding={16}
        flexDirection="column"
        alignItems="center"
        gap={10}
      >
        {
          apiService.apisArray.map((api) => {
            const template = templateService.templates.get(api.template_id)
            const model = modelsService.models.get(api.model_id)
            return (
                <Pane
                    key={api.id}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    border="default"
                    padding={15}
                    borderRadius={5}
                    alignItems="center"
                    onClick={() => {
                      // @ts-ignore
                      apiService.setActiveApi(api.id)
                      navigate('/apis/events')
                    }}
                >
                  <Pane display="flex" flexDirection="column">
                    <Heading size={500}>{api.name}</Heading>
                    <Text>{template?.name} • {model?.name}</Text>
                  </Pane>

                  <Pane display="flex" flexDirection="column" alignItems="center">
                    <Strong>Requests</Strong>
                    <Heading size={500}>{apiService.apiUsage?.get(api.id)?.requestCount || 0}</Heading>
                  </Pane>
                  <Pane display="flex" gap={10}>
                    <Tooltip content="Update API">
                      <WrenchIcon color="gray700" onClick={(event) => {
                        // @ts-ignore
                        apiForms.setValues(api)
                        setSaveTemplateDialog(true)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                    <Tooltip content="API Reference">
                      <CodeIcon color="gray700" onClick={(event) => {
                        setSelectedAPI(api)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                    <Tooltip content="Delete">
                      <DeleteIcon color="red500" onClick={(event) => {
                        setDeleteItem(api.id)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                  </Pane>
                </Pane>
            )
          })
        }
      </Pane>
      <HttpSnippetModal selectedAPI={selectedAPI} setSelectedAPI={setSelectedAPI}/>
      <Dialog
        isShown={saveTemplateDialog}
        title="Create API"
        confirmLabel="Save"
        onCloseComplete={() => setSaveTemplateDialog(false)}
        isConfirmDisabled={!apiForms.isValid}
        onConfirm={() => apiForms.handleSubmit()}
      >
        <Pane display="flex" flexDirection="column" gap={10}>
          <TextInputField
              name="name"
              label="Name"
              value={apiForms.values.name}
              onChange={apiForms.handleChange}
              validationMessage={apiForms.touched.name && apiForms.errors.name}
          />
          <Autocomplete
              title="Templates"
              onChange={changedItem => apiForms.setFieldValue('template_id', changedItem.id)}
              initialInputValue={templateService.templates.get(apiForms.values.template_id)?.name}
              items={templateOptions}
          >
            {props => {
              const { getInputProps, getRef, openMenu } = props
              return (
                  <TextInputField
                      label="Select Template"
                      placeholder="Not Selected"
                      validationMessage={apiForms.touched.template_id && apiForms.errors.template_id}
                      ref={getRef}
                      {...getInputProps({
                        onFocus: () => openMenu(),
                      })}
                  />
              )
            }}
          </Autocomplete>
          <Autocomplete
              title="Models"
              onChange={changedItem => apiForms.setFieldValue('model_id', changedItem.id)}
              initialInputValue={modelOptions.find(({id})=>id===apiForms.values.model_id)?.name}
              items={modelOptions}
          >
            {props => {
              const { getInputProps, getRef, openMenu } = props
              return (
                  <TextInputField
                      label="Select Model"
                      placeholder="Not Selected"
                      validationMessage={apiForms.touched.model_id && apiForms.errors.model_id}
                      ref={getRef}
                      {...getInputProps({
                        onFocus: () => openMenu(),
                      })}
                  />
              )
            }}
          </Autocomplete>
          {/*<RateLimit apiForms={apiForms} type='user_limit'/>*/}
          {/*<RateLimit apiForms={apiForms} type='workspace_limit'/>*/}
        </Pane>
      </Dialog>
      <Dialog
          isShown={!!deleteItem}
          title={`Delete Confirmation`}
          intent="danger"
          confirmLabel="Delete"
          onCloseComplete={() => {
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
          isConfirmDisabled={deleteConfirmation !== apiService.apis.get(deleteItem||"")?.name}
          onConfirm={() => {
            apiService.delete(deleteItem as string)
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
      >
        <TextInputField
            label={`Are Your Sure you want to Delete ${apiService.apis.get(deleteItem || "")?.name}?`}
            description="Please type in full API name to confirm deletion"
            placeholder={apiService.apis.get(deleteItem||"")?.name}
            value={deleteConfirmation}
            onChange={(e: any)=>setDeleteConfirmation(e.target.value)}
        />
      </Dialog>
    </Pane>
  );
};


const CodeSnippet = ({heading, curlSnippet, language, headingColor}: {
  heading: string, curlSnippet: string, language?: string, headingColor?: string
}) => (
    <Pane
        border="default"
        borderRadius={5}
        padding={10}
        background="tint2"
        marginTop={10}
    >
      <Heading width="100%" marginBottom={10} size={600} color={headingColor}>{heading}</Heading>
      <SyntaxHighlighter
          language={language || "bash"}
          style={monokaiSublime}
          customStyle={{borderRadius: 5}}
      >
        {curlSnippet}
      </SyntaxHighlighter>
      <Pane display="flex" justifyContent="flex-end">
        <Tooltip content="Copy">
          <DuplicateIcon onClick={() => {
            navigator.clipboard.writeText(curlSnippet)
            toaster.success('Copied to clipboard')
          }}/>
        </Tooltip>
      </Pane>
    </Pane>
)

const HttpSnippetModal = observer((
    {selectedAPI, setSelectedAPI}: {selectedAPI: API | undefined, setSelectedAPI: (show: API | undefined) => void}
) => {
    const { openAIService, workspaceService: {activeWorkspaceId, activeWorkspace}, templateService } = useRootServices()

    const [ connectType, setConnectType] = useState<string>('API Key')
    const [ trySelected, setTrySelected] = useState<boolean>(false)
    const [ response, setResponse ] = useState<{statusCode: string, result: string}>({statusCode: '', result: ''})

    const [ showClientAuthentication, setShowClientAuthentication ] = useState<boolean>(false)
    const [ showGenerateKeyDialog, setShowGenerateKeyDialog ] = useState<boolean>(false)
    const [ showOpenAPIDialog, setShowOpenAPIDialog ] = useState<boolean>(false)

    const templateParams = [...templateService.getParams(selectedAPI?.template_id || ""), connectType]
    const schema = yup.object(templateParams.reduce((acc, param) => (
        {...acc, [param]: yup.string().required(`${param} is required`)}
    ), {} as Record<string, yup.StringSchema>))

    const formik = getForm({} as Record<string, string>, schema)({
      onSubmit: (_) => sendRequest()
    })

    useEffect(() => {
      const initialValues = templateParams.reduce(
          (acc, param) => ({...acc, [param]: ''}), {} as Record<string, string>
      )
      formik.setValues(initialValues)
    }, [selectedAPI])

    useEffect(() => {
      setShowOpenAPIDialog(trySelected && !activeWorkspace?.openai_api_key)
    }, [trySelected, activeWorkspace])

    const authorization = (connectType=='API Key'? "<BACKEND_API_KEY>" : "<CLIENT_TOKEN>")
    const params = {
      url: `https://edge.nocoai.io/open_ai/completions/${selectedAPI?.id}`,
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${
          (trySelected && formik.values[connectType]) || authorization
        }`,
        "X-WORKSPACE-ID": activeWorkspaceId || "<WORKSPACE_ID>",
      },
      body: {
        "params": templateParams.reduce((acc, param) => (
            {...acc, [param]: trySelected && formik.values[param] || "<PARAM_VALUE>"}
        ), {} as Record<string, string>),
        "openai_params": Object.fromEntries(openAIService.openaiParams.entries())
      }
    };
    const curlSnippet = CurlGenerator(params as any)

    const sendRequest = async () => {
      setResponse({statusCode: '', result: ''})
      const res = fetch(params.url, {
        method: params.method,
        headers: params.headers,
        body: JSON.stringify(params.body)
      })
      const {status, statusText} = await res
      const result = await res.then(r => r.json())
      setResponse({statusCode: `(${status} ${statusText})`, result: JSON.stringify(result, null, 2)})
    }

    const description = (
        <Text size={300}>
          Click{" "}
          <Link
            target="_blank"
            onClick={() => connectType!='API Key'? setShowClientAuthentication(true)
            : setShowGenerateKeyDialog(true)}
          >
            here
          </Link>{" "}
          to get your {
          connectType!='API Key'? 'Client Token.'
              : 'API Key. Make sure not to expose this on client, use client token instead.'
        }
        </Text>
    )

    return (
        <>
          <Dialog
              isShown={selectedAPI !== undefined}
              title="API Reference"
              onCloseComplete={() => setSelectedAPI(undefined)}
              hasFooter={false}
          >
            <Pane display="flex" marginBottom={30} flexDirection="column">
              <Pane>
                <SelectField
                    onChange={(e) => setConnectType(e.target.value)}
                    width="100%"
                    label="How do you want to connect?"
                    description={description}
                >
                  <option value="API Key" selected={connectType === "API Key"}>Backend API Key</option>
                  <option value="Client Token" selected={connectType === "Client Token"}>Client Token</option>
                </SelectField>
              </Pane>
              {
                  trySelected && (
                      <>
                        <OpenAISettingsPopover text="OpenAI Settings"/>
                        <TextInputField
                            label={connectType}
                            name={connectType}
                            marginTop={10}
                            value={formik.values[connectType]}
                            onChange={formik.handleChange}
                            validationMessage={formik.touched[connectType] && formik.errors[connectType]}
                        />
                        <Pane
                            display="flex"
                            border="default"
                            borderRadius={5}
                            padding={10}
                            flexWrap="wrap"
                            justifyContent="space-between"
                        >
                          <Heading width="100%" marginBottom={10} size={600}>Parameters</Heading>
                          {
                            templateParams.filter(param => param !== connectType).map(param => (
                                <TextInputField
                                    key={param}
                                    label={param}
                                    name={param}
                                    width="49%"
                                    value={formik.values[param]}
                                    onChange={formik.handleChange}
                                    validationMessage={formik.touched[param] && formik.errors[param]}
                                />
                            ))
                          }
                        </Pane>
                      </>
                  )
              }
              <CodeSnippet heading="Curl Request" curlSnippet={curlSnippet} />
              {
                trySelected && response.statusCode && (
                  <CodeSnippet
                    heading={`Response ${response.statusCode}`}
                    curlSnippet={response.result}
                    headingColor={response.statusCode.startsWith('(2') ? '#00C851' : '#ff4444'}
                    language="json"
                  />
                )
              }
              {
                trySelected? (
                  <Pane
                    marginTop={10}
                    display="flex"
                    justifyContent="flex-end"
                    gap={10}
                  >
                    <Button onClick={() => setTrySelected(false)}>Back</Button>
                    <Button appearance="primary" onClick={() => formik.handleSubmit()}>Send Request</Button>
                  </Pane>
                ): (
                  <Button marginTop={10} onClick={() => setTrySelected(true)}>Try it out</Button>
                )
              }
            </Pane>
          </Dialog>
          <ClientAuthorizationDialog
              show={showClientAuthentication}
              setShow={setShowClientAuthentication}
          />
          <WorkspaceAPIKeyDialog
              show={showGenerateKeyDialog}
              setShow={setShowGenerateKeyDialog}
          />
          <OpenAIAPIKeyDialog
              show={showOpenAPIDialog}
              setShow={setShowOpenAPIDialog}
          />
        </>
    )
})


export default observer(APIPage);
