import React from "react";
import {Image, Pane} from "evergreen-ui";
import {Rings} from "react-loader-spinner";

const PageLoading = () =>  {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Image src="/images/logo-full.png" height={30}/>
          <Pane display="flex" alignItems="center" justifyContent="center" marginTop={20}>
            <Rings
                height="80"
                width="80"
                color="black"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
            />
          </Pane>
        </Pane>
      </Pane>
    )
}

export default PageLoading;