import {ApiResult} from "../client/core/ApiResult";

export type Interceptor = (result: ApiResult) => void;

const interceptors: Interceptor[] = [];

export const addApiResultInterceptor = (interceptor: Interceptor): void => {
  interceptors.push(interceptor);
};

export const handleApiResult = (result: ApiResult): void => {
  interceptors.forEach((interceptor) => {
    try {
      interceptor(result);
    } catch (error) {
      // do nothing
    }
  });
};