import * as yup from "yup";


export default yup.object({
    id: yup.string(),
    name: yup.string()
        .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
        .required('API name is required'),
    template_id: yup.string()
        .required('Template must be selected'),
    model_id: yup.string()
        .required('Template must be selected'),
})
