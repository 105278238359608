/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { API } from '../models/API';
import type { APIRequest } from '../models/APIRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * Get
     * @param xWorkspaceId
     * @param authorization
     * @returns API Successful Response
     * @throws ApiError
     */
    public static get(
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Array<API>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns API Successful Response
     * @throws ApiError
     */
    public static upsert(
        requestBody: APIRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<API> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param apiId
     * @param xWorkspaceId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static delete(
        apiId: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/{api_id}',
            path: {
                'api_id': apiId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
