import {action, computed, makeAutoObservable, observable, runInAction,} from 'mobx'
import {DelegatorResponse, OpenaiService as OpenaiAPIService} from "../client";
import Mustache from "mustache";
import {toaster} from "evergreen-ui";
import ModelsService from "./models.service";
import TemplateService from "./template.service";
import WorkspaceService from "./workspace.service";

export type OpenAIParams = "temperature" | "max_tokens" | "top_p" | "n" | "stop" | "presence_penalty" | "frequency_penalty"

export default class OpenAIService {
    @observable activeWorkspaceId?: string
    @observable promptResult: string = ''
    @observable openaiParams = observable.map<OpenAIParams, any>({})
    @observable abortController?: AbortController

    constructor(
        private modelService: ModelsService,
        private templateService: TemplateService,
        private workspaceService: WorkspaceService,
    ) {
        makeAutoObservable(this)
    }

    @computed get isRunning() {
        return !!this.abortController
    }

    @action setPromptResult = (promptResult: string) => this.promptResult = promptResult
    @action resetPromptResult = () => this.promptResult = ''
    @action setOpenAIParams = (key: OpenAIParams, value: any) => {
        this.openaiParams.set(key, key==='stop'? value: Number(value))
    }
    @action resetOpenAIParams = () => this.openaiParams.clear()

    @action fetchCompletion = (template: string): DelegatorResponse | void => {
        const workspace = this.workspaceService.activeWorkspace
        if (!workspace || !workspace.openai_api_key)
            return toaster.danger("No OpenAI API Key found")
        const body = JSON.stringify({
            stream: true,
            model: "{{model_id}}",
            prompt: `${template}${this.promptResult}`,
            ...Object.fromEntries(this.openaiParams.entries()),
        });
        return {
            url: "https://api.openai.com/v1/completions",
            data: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${workspace.openai_api_key.key}`,
                },
                body,
            },
        };
    }

    @action generate = async (template: string, params: {[key: string]: string}) => {
        debugger
        await this.runWithAbortController(async () => {
            const delegatorResponse = this.fetchCompletion(template)
            if (!delegatorResponse) return

            let model_id = this.modelService.selectedOutputModel
            if (!model_id) return toaster.danger("No model selected")

            const model = this.modelService.models.get(model_id)
            if (model) model_id = model.openai_model_name ? model.openai_model_name : model.base_model_id

            const body = Mustache.render(
                delegatorResponse.data.body, {...params, model_id}
            )
            const result = await fetch(delegatorResponse.url, {
                ...delegatorResponse.data, body, signal: this.abortController?.signal
            })
            if (!result.ok)
                return toaster.danger(
                    `OpenAI API Error ${result.statusText}`, {description: await result.text()}
                )
            const reader = (result.body as any).getReader();
            const decoder = new TextDecoder();
            while (true) {
                const {done, value} = await reader.read();
                if (done) break;
                decoder.decode(value)
                    .split('\n')
                    .filter((line: string) => line.startsWith('data:'))
                    .map((line: string) => line.substring(6))
                    .forEach((line: string) => {
                        if (line === "[DONE]") return
                        const data = JSON.parse(line)
                        this.setPromptResult(this.promptResult + data.choices[0].text)
                    })
            }
        })
    }

    @action abort = () => {
        if (this.abortController) this.abortController.abort()
        this.abortController = undefined
        toaster.danger("Aborted")
    }

    private runWithAbortController = async (func: () => any) => {
        if (this.abortController) this.abortController.abort()
        this.abortController = new AbortController()
        try {
            await func()
        } catch (err) {
            if ((err||{} as any)?.name !== 'AbortError') toaster.danger(
                "Unknown Error", {description: "Please try again later."}
            )
        }
        this.abortController = undefined
    }
}
