import React from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {
    APIPage,
    APIEvents,
    TemplatePage,
    ModelsPage,
    ModelFineTuneRunner,
    ModelDatasets,
    UserAccount,
    WorkspaceAdmin,
    Login,
    Workspace,
    Run,
} from "./Pages";
import Layout from "./Layout";
import { Pane } from "evergreen-ui";

import "./index.css";
import {RootServiceContextProvider, useRootServices} from "./services";
import { observer } from "mobx-react-lite";
import PageLoading from "./PageLoading";


const Protected = observer(({redirectPath = '/login', children}: any) => {
    const { authService: {isLoggedIn, isInitialized} } = useRootServices()
    if (!isInitialized) return <PageLoading />
    return !isLoggedIn? <Navigate to={redirectPath} replace /> : children
})

export default function App() {
  return (
    <RootServiceContextProvider>
        <ProSidebarProvider>
          <BrowserRouter>
            <Pane flexDirection="row">
              <Routes>
                <Route index path="login" element={<Login />} />
                <Route path="workspace" element={
                  <Protected><Workspace /></Protected>
                } />
                <Route
                  path="api"
                  element={
                      <Protected>
                          <Layout title="APIs">
                              <APIPage />
                          </Layout>
                      </Protected>
                  }
                />
                <Route
                    path="apis/events"
                    element={
                      <Protected>
                        <Layout title="API Events">
                          <APIEvents />
                        </Layout>
                      </Protected>
                    }
                />
                <Route
                  path="templates"
                  element={
                    <Protected>
                        <Layout title="Templates">
                          <TemplatePage />
                        </Layout>
                    </Protected>
                  }
                />
                <Route
                    path="templates/editor"
                    element={
                      <Protected>
                        <Layout title="Template Editor">
                          <Run />
                        </Layout>
                      </Protected>
                    }
                />
                <Route
                  path="models"
                  element={
                    <Protected>
                        <Layout title="Models">
                            <ModelsPage />
                        </Layout>
                    </Protected>
                  }
                />
                <Route
                  path="models/fine-tune-model"
                  element={
                    <Protected>
                        <Layout title="Fine Tune Model">
                            <ModelFineTuneRunner />
                        </Layout>
                    </Protected>
                  }
                />
                <Route
                    path="models/dataset"
                    element={
                      <Protected>
                        <Layout title="Dataset">
                          <ModelDatasets />
                        </Layout>
                      </Protected>
                    }
                />
                <Route
                  path="user-account"
                  element={
                    <Protected>
                        <Layout title="UserAccount">
                            <UserAccount />
                        </Layout>
                    </Protected>
                  }
                />
                <Route
                    path="workspace-admin"
                    element={
                      <Protected>
                        <Layout title="Workspace Admin">
                          <WorkspaceAdmin />
                        </Layout>
                      </Protected>
                    }
                />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </Pane>
          </BrowserRouter>
        </ProSidebarProvider>
    </RootServiceContextProvider>
  );
}
