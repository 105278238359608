import * as yup from "yup";

export default yup.object({
  id: yup.string(),
  name: yup.string()
      .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
      .required('Name is required'),
  template: yup.string()
      .test('is-not-empty', 'Template name must not be empty', (value) => value !== '')
      .required('Template is required'),
})
