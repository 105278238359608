import {
    observable,
    action,
    makeAutoObservable,
    runInAction, autorun, computed,
} from 'mobx'
import {ModelsService as ModelsAPIService, Model, Template, FineTuneTaskType, TaskState} from "../client";
import WorkspaceService from "./workspace.service";
import TemplatesService from "./template.service";
import {objectId} from "../utils";
import AuthService from "./auth.service";
import {database} from "../utils/firebase";
import {ref, push, set, onValue} from "firebase/database";
import {toaster} from "evergreen-ui";


type ModelBase = {
    id: string;
    name: string;
    openai_model_name?: string;
    canBeFineTuned: boolean,
}

export type Dataset = {
    params: Record<string, string>
    prompt: string;
    completion: string;
}


export type FineTuneEvent = {
    task_type: FineTuneTaskType,
    state: TaskState,
    retry_count: number,
    title: string,
    message: string,
}

export default class ModelsService {

    @observable models = observable.map<string, Model>()
    @observable openAIModels = observable.map<string, ModelBase>()
    @observable activeModelId?: string
    @observable datasets = observable.map<string, Dataset>()
    @observable fineTuneEvents = observable.map<string, FineTuneEvent>()
    @observable selectedOutputModel?: string
    @observable selectedOutputModelError: boolean = false
    @observable fetchFineTuneEventSubscription?: () => void

    constructor(
        private authService: AuthService,
        private workspaceService: WorkspaceService,
        private templateService: TemplatesService,
    ) {
        makeAutoObservable(this)

        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetch())
        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetchBaseModels())
        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetchFineTuneEvents())
    }

    @computed get modelsArray(): Model[] {
        return Array.from(this.models.values())
    }

    @computed get allBaseModels(): ModelBase[] {
        const userModels = this.modelsArray.map(({id, name, openai_model_name}) =>
            ({id, name, openai_model_name, canBeFineTuned: true}))
        .filter(({openai_model_name}) => openai_model_name)

        return Array.from(this.openAIModels.values()).concat(userModels)
    }

    @computed get activeModel(): Model | undefined {
        return this.models.get(this.activeModelId || '')
    }

    @computed get outputModelOptions(): { value: string, label: string }[] {
        return this.allBaseModels.map(model=>({label: model.name, value: model.id}))
    }

    @computed get datasetRef() {
        return ref(database, `dataset/${this.workspaceService.activeWorkspaceId}/${this.activeModelId}`)
    }

    @action setActiveModel = (model_id: string) => {
        this.activeModelId = model_id
    }

    @action setSelectedOutputModel = (model_id: string) => {
        this.selectedOutputModel = model_id
    }

    @action fetchBaseModels = async () => {
        if (!this.workspaceService.activeWorkspaceId) return
        try {
            const openAIModels = await ModelsAPIService.getOpenAiModels()
            runInAction(() => {
                this.openAIModels.replace(Object.keys(openAIModels).reduce((map, model) => {
                    map.set(model, {
                        id: model, name: model, openai_model_name: model, canBeFineTuned: openAIModels[model]
                    })
                    return map
                }, observable.map<string, ModelBase>()))
                this.selectedOutputModel = this.allBaseModels[0].id
            })
        } catch (err) {
            
        }
    }

    @action fetch = async () => {
        try {
            const models = await ModelsAPIService.get()
            runInAction(() => {
                this.models.replace(models.reduce((map, model) => {
                    map.set(model.id, model)
                    return map
                }, observable.map<string, Model>()))
            })
        } catch (err) {
            
        }
    }

    @action upsert = async (name: string, base_model_id: string, template_id: string, id?: string) => {
        try {
            const template = await ModelsAPIService.upsert({
                id,
                name,
                base_model_id,
                template_id,
            })
            runInAction(() => {
                this.models.set(template.id, template)
            })
            toaster.success('Model saved')
        } catch (err) {
            
        }
    }

    @action delete = async (model_id: string) => {
        try {
            await ModelsAPIService.delete(model_id)
            runInAction(() => {
                this.models.delete(model_id)
            })
            toaster.success('Model deleted')
        } catch (err) {
            
        }
    }

    @action addDataset = async (params: Record<string, string>, completion: string) => {
        if (!this.activeModelId) return

        const prompt = this.templateService.render(this.activeModel?.template_id || '', params)
        if (!prompt) return

        try {
            await set(push(this.datasetRef), {params, prompt, completion} as Dataset)
            toaster.success('Dataset added')
        } catch (err) {
            
        }
    }

    @action fineTuneModel = async () => {
        if (!this.activeModelId) return
        try {
            await ModelsAPIService.finetuneModel(this.activeModelId, objectId())
            toaster.success('Fine-tuning started')
            return Promise.resolve()
        } catch (err) {
          return Promise.reject(err)
        }
    }

    @action fetchFineTuneEvents = async () => {
        if (this.fetchFineTuneEventSubscription) this.fetchFineTuneEventSubscription()

        const itemRef = ref(database, `fine_tune_events/${this.workspaceService.activeWorkspaceId}`)
        this.fetchFineTuneEventSubscription = onValue(itemRef, (snapshot) => {
            const item = snapshot.val() as { [model_id: string]: FineTuneEvent }
            runInAction(() => Object.keys(item).forEach(
                model_id => this.fineTuneEvents.set(model_id, item[model_id])
            ))
        });
    }
}
