import {
    computed,
    observable,
    action,
    makeAutoObservable,
    runInAction, autorun,
} from 'mobx'

import {UserAccount, UserAccountService} from "../client";
import {ClientError} from "../client/core/request";
import AuthService from "./auth.service";
import {toaster} from "evergreen-ui";

export default class AccountService {
    private authService: AuthService

    @observable userAccount?: UserAccount
    @observable isSetupRequired?: boolean

    constructor(authService: AuthService) {
        makeAutoObservable(this)
        this.authService = authService

        autorun(() => this.authService.isLoggedIn && this.fetch())
    }

    @action
    fetch = async () => {
        try {
            const account = await UserAccountService.get(this.authService?.token?.token)
            runInAction(() => {
                this.userAccount = account
            });
        } catch (err) {
            if ((err as ClientError)?.error_code === "AccountNotFound") {
                runInAction(() => {
                    this.isSetupRequired = true
                });
            }
        }
    }

    @action
    create = async (name: string, attributes: {[key: string]: string}) => {
        if (!this.authService.isLoggedIn) return
        try {
            const account = await UserAccountService.create({name, attributes})
            runInAction(() => {
                this.userAccount = account
                this.isSetupRequired = false
            });
            toaster.success("Account created successfully")
        } catch (err) {
        }
    }

    @action
    update = async (name: string, attributes: {[key: string]: string}) => {
        if (!this.authService.isLoggedIn) return
        try {
            const account = await UserAccountService.update({name, attributes})
            runInAction(() => {
                this.userAccount = account
                this.isSetupRequired = false
            });
            toaster.success("Account updated successfully")
        } catch (err) {
        }
    }
}
