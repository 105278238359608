/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDatasetRequest } from '../models/AddDatasetRequest';
import type { Dataset } from '../models/Dataset';
import type { Model } from '../models/Model';
import type { ModelRequest } from '../models/ModelRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModelsService {

    /**
     * Get
     * @param xWorkspaceId
     * @param authorization
     * @returns Model Successful Response
     * @throws ApiError
     */
    public static get(
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Array<Model>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Model Successful Response
     * @throws ApiError
     */
    public static upsert(
        requestBody: ModelRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Model> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/models/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Model Successful Response
     * @throws ApiError
     */
    public static multiGet(
        requestBody: Array<string>,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Array<Model>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/multi',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Base Models
     * @param xWorkspaceId
     * @param authorization
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static getOpenAiModels(
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Record<string, boolean>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/open_ai_models',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param modelId
     * @param xWorkspaceId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static delete(
        modelId: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/models/{model_id}',
            path: {
                'model_id': modelId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Datasets
     * @param modelId
     * @param startCreatedAt
     * @param limit
     * @param xWorkspaceId
     * @param authorization
     * @returns Dataset Successful Response
     * @throws ApiError
     */
    public static getDataset(
        modelId: string,
        startCreatedAt?: string,
        limit: number = 50,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Array<Dataset>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/{model_id}/datasets',
            path: {
                'model_id': modelId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            query: {
                'start_created_at': startCreatedAt,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Dataset
     * @param modelId
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Dataset Successful Response
     * @throws ApiError
     */
    public static addDataset(
        modelId: string,
        requestBody: AddDatasetRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Dataset> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/models/{model_id}/datasets',
            path: {
                'model_id': modelId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Finetune
     * @param modelId
     * @param taskId
     * @param xWorkspaceId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static finetuneModel(
        modelId: string,
        taskId: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/models/{model_id}/finetune/{task_id}',
            path: {
                'model_id': modelId,
                'task_id': taskId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
