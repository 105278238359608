import React, {useCallback, useEffect, useState} from "react";
import {
  Autocomplete,
  Button,
  EditIcon,
  Heading,
  RefreshIcon,
  MaximizeIcon,
  NewLayersIcon,
  Pane,
  PlayIcon,
  ResetIcon,
  SelectMenu,
  StopIcon,
  TextareaField,
  TextInputField,
  Tooltip,
  WrenchIcon,
} from "evergreen-ui";
import {useRootServices} from "../../services";
import {getForm, useModelForms} from "../../forms";
import * as yup from "yup";
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate} from "react-router-dom";
import 'react-rangeslider/lib/index.css'
import {OpenAISettingsPopover} from "../../dialogs";
import OpenAIAPIKeyDialog from "../../dialogs/openAIAPIKeyDialog";

const ModelDatasets = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const modelNameRef = React.useRef<HTMLHeadingElement>(null)

  const { openAIService, modelsService, workspaceService: {activeWorkspace}, templateService } = useRootServices()

  const templateOptions = templateService.templatesArray
      .map(template=>({...template, toString: () => template.name}))

  const baseModelOptions = modelsService.allBaseModels
      .filter(({canBeFineTuned}) => canBeFineTuned)
      .map(models=>({...models, toString: () => models.name}))

  const modelId = new URLSearchParams(location.search).get('model') || modelsService.allBaseModels[0].id
  const model = modelsService.models.get(modelId)

  const modelForm = useModelForms({
    onSubmit: (values) => {
      modelsService.upsert(
          modelNameRef.current?.innerText || values.name,
          values.base_model_id,
          values.template_id,
          values.id || undefined,
      )
    },
    initialValues: {
      name: model?.name || '',
      base_model_id: model?.base_model_id || modelsService.allBaseModels[0].id,
      template_id: model?.template_id || templateOptions[0].id,
      id: model?.id || undefined,
    }
  })

  const templateId = modelForm.values.template_id
  const baseModelId = modelForm.values.base_model_id

  const [ expandItem, setExpandItem ] = useState<{[key: string]: boolean}>({});
  const [ showOpenAPIDialog, setShowOpenAPIDialog ] = useState<boolean>(false);

  const params = templateId? templateService.getParams(templateId): []
  const finetuned = !!modelsService.activeModel?.openai_model_name

  const schema = yup.object(params.reduce((acc, param) => {
    acc[param] = yup.string().required(`${param} is required`); return acc
  }, {} as {[key: string]: yup.StringSchema}))
  const defaults = params.reduce((acc, param) => {
    acc[param] = ''; return acc
  }, {} as {[key: string]: string})

  const formik = getForm(defaults, schema)({
    onSubmit: (values) => {
      const templateValue = templateService.templates.get(templateId || '')
      if (!templateValue) return
      openAIService.generate(templateValue.template, values)
    },
  })

  const addDataset = useCallback(() => {
    modelsService.addDataset(formik.values, openAIService.promptResult).then(() => {
      openAIService.resetPromptResult()
      formik.resetForm()
    })
  }, [formik.values, openAIService.promptResult])

  const {
    outputModelOptions,
    setSelectedOutputModel,
    selectedOutputModel,
  } = modelsService

  useEffect(() => {
    setShowOpenAPIDialog(!activeWorkspace?.openai_api_key?.key)
  }, [showOpenAPIDialog])

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="space-between" alignItems="center">
        <Pane display="flex" flexDirection="row" alignItems="center" gap={10}>
          <Heading
              size={800}
              ref={modelNameRef}
              contentEditable
          >
            {modelForm.values.name}
          </Heading>
          <EditIcon size={20} onClick={() => modelNameRef.current?.focus()}/>
        </Pane>
        <Pane display={finetuned? "none": "flex"} gap={5}>
          <Button
              appearance="primary"
              iconAfter={RefreshIcon}
              onClick={() => modelForm.handleSubmit()}
          >
            Save Model
          </Button>
          <Button
            appearance="minimal"
            iconAfter={WrenchIcon}
            onClick={() => {
              modelsService.fineTuneModel().then(() => navigate("/models"))
            }}
          >
            FineTune
          </Button>
        </Pane>
      </Pane>
      <Pane display="flex" padding={16} flexDirection="column" gap={15}>
        <Pane display="flex" flexDirection="row" gap={10}>
          <Autocomplete
              title="Templates"
              onChange={changedItem => modelForm.setFieldValue('template_id', changedItem.id)}
              initialInputValue={templateOptions.find(({id})=>id===templateId)?.name}
              items={templateOptions}
          >
            {props => {
              const { getInputProps, getRef, openMenu } = props
              return (
                  <TextInputField
                      label="Template"
                      placeholder="Not Selected"
                      validationMessage={modelForm.touched.template_id && modelForm.errors.template_id}
                      ref={getRef}
                      {...getInputProps({
                        onFocus: () => openMenu()
                      })}
                  />
              )
            }}
          </Autocomplete>
          <Autocomplete
              title="Base Model"
              onChange={changedItem => modelForm.setFieldValue('base_model_id', changedItem.id)}
              initialInputValue={baseModelOptions.find(({id})=>id===baseModelId)?.name}
              items={baseModelOptions}
          >
            {props => {
              const { getInputProps, getRef, openMenu } = props
              return (
                  <TextInputField
                      label="Base Model"
                      placeholder="Not Selected"
                      validationMessage={formik.touched.template_id && formik.errors.template_id}
                      ref={getRef}
                      {...getInputProps({
                        onFocus: () => openMenu()
                      })}
                  />
              )
            }}
          </Autocomplete>
        </Pane>
        <Pane display="flex" flexDirection="row" gap={15}>
          <Pane display="flex" flex={1} flexDirection="column" gap={10}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size={600}>Parameters</Heading>
              <Pane display="flex" gap={5} flexDirection="row">
                <OpenAISettingsPopover />
                <Button
                    appearance="minimal"
                    iconBefore={ResetIcon}
                    onClick={()=> formik.resetForm()}
                >
                  Reset
                </Button>
                {
                  openAIService.isRunning? (
                      <Button
                          iconBefore={StopIcon}
                          onClick={() => openAIService.abort()}
                      >
                        Stop
                      </Button>
                  ): (
                      <Button
                          iconBefore={PlayIcon}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formik.isValid}
                      >
                        Run
                      </Button>
                  )
                }
              </Pane>
            </Pane>
            <Pane
              display="flex"
              border="default"
              flexDirection="column"
              padding={15}
              borderRadius={5}
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {
                  params.map(param => {
                    const Component = expandItem[param] ? TextareaField : TextInputField
                    return (
                      <Pane display="flex" alignItems={"center"} gap={5} key={param}>
                        <Component
                            flex={1}
                            name={param}
                            label={param}
                            placeholder="Enter value"
                            value={formik.values[param]}
                            onChange={formik.handleChange}
                            validationMessage={formik.touched[param] && formik.errors[param]}
                        />
                        <Tooltip content={expandItem[param] ? "Collapse" : "Expand"}>
                          <MaximizeIcon size={10} onClick={()=>{
                            setExpandItem({...expandItem, [param]: !expandItem[param]})
                          }} alignSelf="flex-start"/>
                        </Tooltip>
                      </Pane>
                    )
                  })
              }
            </Pane>
          </Pane>
          <Pane display="flex" flex={2} flexDirection="column" gap={10}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size={600}>Output</Heading>
              <Pane display={finetuned? "none": "flex"} gap={5} alignItems="center">
                <Button appearance="minimal" onClick={()=>navigate("/models/dataset")}>View Datasets</Button>
                <Button
                  iconBefore={NewLayersIcon}
                  onClick={addDataset}
                  disabled={!formik.isValid || openAIService.promptResult === ''}
                >
                  Add Dataset
                </Button>
                <Pane display="flex" gap={5} alignItems="center">
                  <SelectMenu
                      title="Select Model To use only for Output"
                      options={outputModelOptions}
                      onSelect={changedItem => setSelectedOutputModel(changedItem.value as string)}
                      selected={selectedOutputModel || undefined}
                  >
                    <Button>{selectedOutputModel || 'Select Model To Use'}</Button>
                  </SelectMenu>
                </Pane>
              </Pane>
            </Pane>
            <Pane display="flex" border="default" padding={15} borderRadius={5}>
              <TextareaField
                width="100%"
                inputHeight={300}
                label="Open AI Result"
                description="Update the results and and train to fine-tune the model"
                placeholder="Result"
                value={openAIService.promptResult}
                onChange={(e: any) => openAIService.setPromptResult(e.target.value)}
              />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <OpenAIAPIKeyDialog
          show={showOpenAPIDialog}
          setShow={setShowOpenAPIDialog}
          onCanceled={() => navigate("/models")}
      />
    </Pane>
  );
};

export default observer(ModelDatasets);
