/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddUserToWorkspaceRequest } from '../models/AddUserToWorkspaceRequest';
import type { ClientAuthorizationRequest } from '../models/ClientAuthorizationRequest';
import type { UserWorkspace } from '../models/UserWorkspace';
import type { Workspace } from '../models/Workspace';
import type { WorkspaceRequest } from '../models/WorkspaceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkspaceApiService {

    /**
     * Get
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static get(
        authorization?: string,
    ): CancelablePromise<Array<Workspace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/workspace_api/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static update(
        requestBody: WorkspaceRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/workspace_api/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static create(
        requestBody: WorkspaceRequest,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/workspace_api/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns UserWorkspace Successful Response
     * @throws ApiError
     */
    public static addUser(
        requestBody: AddUserToWorkspaceRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<UserWorkspace> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/workspace_api/users',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove User
     * @param userAccountId
     * @param xWorkspaceId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeUser(
        userAccountId: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/workspace_api/users/{user_account_id}',
            path: {
                'user_account_id': userAccountId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Api Key
     * @param name
     * @param xWorkspaceId
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static generateApiKey(
        name: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/workspace_api/apikey/{name}',
            path: {
                'name': name,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Api Key
     * @param name
     * @param xWorkspaceId
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static removeApiKey(
        name: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/workspace_api/apikey/{name}',
            path: {
                'name': name,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert Client Token
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static upsertClientToken(
        requestBody: ClientAuthorizationRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/workspace_api/client_tokens',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Client Token
     * @param audience
     * @param xWorkspaceId
     * @param authorization
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public static removeClientToken(
        audience: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Workspace> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/workspace_api/client_tokens/{audience}',
            path: {
                'audience': audience,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
