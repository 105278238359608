import React, {useEffect, useMemo, useState} from "react";
import {
  Tooltip,
  PlusIcon,
  RefreshIcon,
  DeleteIcon,
  DuplicateIcon,
  Table,
  Link,
  TextInputField,
  Pane,
  Text,
  Heading,
  Button,
  Dialog, Strong,
  KeyIcon, TrashIcon, Code, IconButton,
  Tablist,
  Tab, TextareaField, toaster,
} from "evergreen-ui";

import {getForm, useUserAccountForms, useWorkspaceForms} from "../../forms";
import {observer} from "mobx-react-lite";
import {useRootServices} from "../../services";
import * as yup from "yup";
import {ClientIdTokenType} from "../../client";
import {createdAtDisplay} from "../../utils";
import {ClientAuthorizationDialog, WorkspaceAPIKeyDialog} from "../../dialogs";


const WorkspaceAdmin = () => {
  const { accountService, workspaceService } = useRootServices()
  const { activeWorkspace } = workspaceService

  const [showGenerateKeyDialog, setShowGenerateKeyDialog] = useState<boolean>(false);
  const [showClientAuthentication, setShowClientAuthentication] = useState<boolean>(false);

  const [showDeleteKeyDialog, setShowDeleteKeyDialog] = useState<string | undefined>(undefined);
  const [showDeleteAuthenticationDialog, setShowDeleteAuthenticationDialog] = useState<string | undefined>(undefined);

  const userAccountForm = useUserAccountForms({
    onSubmit: (values) => {
      const { name, company } = values;
      accountService.update(name, {company});
    },
    initialValues: {
      name: accountService.userAccount?.name || "",
      company: accountService.userAccount?.attributes?.company || ""
    },
  });

  const workspaceForms = useWorkspaceForms({
    onSubmit: (values) => {
      workspaceService.update(values.name, values.openai_api_key || undefined);
    },
    initialValues: {
      name: activeWorkspace?.name || "",
      openai_api_key: activeWorkspace?.openai_api_key?.key || ""
    },
  })

  return (
    <Pane width="100%" display="flex" padding={16} flexDirection="column" gap={25}>
      <Pane display="flex" flexDirection="column" gap={25}>
        <Pane display="flex" flexDirection="column" gap={10}>
          <Pane display="flex" flexDirection="row" justifyContent="space-between">
            <Heading size={600}>Workspace Setting</Heading>
            <Button
                iconBefore={RefreshIcon}
                onClick={() => workspaceForms.handleSubmit()}
            >
              Save
            </Button>
          </Pane>
          <Pane
            display="flex"
            border="default"
            padding={15}
            borderRadius={5}
            flexDirection="column"
          >
            <Pane display="flex" alignItems="center" gap={10}>
              <TextInputField
                  flex={1}
                  label="Workspace Id"
                  value={activeWorkspace?.id}
                  disabled
              />
              <Tooltip content={"Copy Workspace Id"}>
                <DuplicateIcon size={20} onClick={() => {
                  navigator.clipboard.writeText(activeWorkspace?.id || "")
                  toaster.success('Copied to clipboard')
                }}/>
              </Tooltip>
            </Pane>
            <TextInputField
              name="name"
              label="Workspace Name"
              placeholder="Example: Sample Workspace"
              value={workspaceForms.values.name}
              onChange={workspaceForms.handleChange}
              validationMessage={workspaceForms.touched.name && userAccountForm.errors.name}
            />
            <TextInputField
              name="openai_api_key"
              width="100%"
              label="OpenAI API Key"
              type="password"
              hint={
                <Text>
                  click{" "}
                  <Link href="https://beta.openai.com/account/api-keys">
                    here
                  </Link>{" "}
                  to get your API key.
                </Text>
              }
              placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              value={workspaceForms.values.openai_api_key}
              onChange={workspaceForms.handleChange}
              validationMessage={workspaceForms.touched.openai_api_key && workspaceForms.errors.openai_api_key}
            />
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" gap={10}>
          <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
          >
            <Heading size={600}>API Keys</Heading>
            <Button iconBefore={PlusIcon} onClick={() => setShowGenerateKeyDialog(true)}>
              Add Key
            </Button>
          </Pane>
          <Table.Body border="default" borderRadius={5}>
            <Table.Head>
              <Table.TextCell>Name</Table.TextCell>
              <Table.TextCell>Secret Key</Table.TextCell>
              <Table.TextCell>Created</Table.TextCell>
              <Table.TextCell></Table.TextCell>
            </Table.Head>
            <Table.Body>
              {
                Object.keys(workspaceService.activeWorkspace?.workspace_api_keys||{}).map((key) => {
                  const apiKey = workspaceService.activeWorkspace?.workspace_api_keys?.[key]
                  return (
                      <Table.Row key={key}>
                        <Table.TextCell>{key}</Table.TextCell>
                        <Table.TextCell>{apiKey?.key}</Table.TextCell>
                        <Table.TextCell>{createdAtDisplay(apiKey?.created_at)}</Table.TextCell>
                        <Table.TextCell>
                          <DeleteIcon color="red500" onClick={()=>setShowDeleteKeyDialog(key)} />
                        </Table.TextCell>
                      </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table.Body>
        </Pane>
        <Pane display="flex" flexDirection="column" gap={10}>
          <Pane display="flex" flexDirection="row" justifyContent="space-between">
            <Heading size={600}>Client Authorization Token</Heading>
            <Button iconBefore={PlusIcon} onClick={() => setShowClientAuthentication(true)}>
              Add Key
            </Button>
          </Pane>
          <Pane display="flex" flexDirection="column" border="default" borderRadius={5}>
            {
              Object.values(workspaceService.activeWorkspace?.client_authorizations||{}).map((client) => {
                return (
                    <Pane
                        key={client.audience}
                        display="flex"
                        flexDirection="row"
                        padding={15}
                        borderBottom="default"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                      <Pane display="flex" flexDirection="row" alignItems="center">
                        <KeyIcon color="rgba(1,1,1,0.5)" size={30} marginRight={10}/>
                        <Pane display="flex" flexDirection="column" gap={3}>
                          <Heading size={500}>Audience <Strong>{client.audience}</Strong></Heading>
                          <Text size={300}>Added on {createdAtDisplay(client.created_at)}</Text>
                        </Pane>
                      </Pane>
                      <Code><Strong>Type </Strong>{client.type.toUpperCase()}</Code>
                      <IconButton
                        intent="danger"
                        icon={TrashIcon}
                        onClick={() => setShowDeleteAuthenticationDialog(client.audience)}
                      >
                        Delete
                      </IconButton>
                    </Pane>
                )
              })
            }
          </Pane>
        </Pane>
      </Pane>
      <ClientAuthorizationDialog
          show={showClientAuthentication}
          setShow={setShowClientAuthentication}
          deleteSelection={showDeleteAuthenticationDialog}
          setDeleteSelection={setShowDeleteAuthenticationDialog}
      />
      <WorkspaceAPIKeyDialog
          show={showGenerateKeyDialog}
          setShow={setShowGenerateKeyDialog}
          deleteSelection={showDeleteKeyDialog}
          setDeleteSelection={setShowDeleteKeyDialog}
      />
    </Pane>
  );
};

export default observer(WorkspaceAdmin);
