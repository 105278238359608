import React, {useEffect, useState} from "react";
import {Button, Heading, Pane, Tab, Tablist, Text, TextInputField,} from "evergreen-ui";
import GoogleButton from 'react-google-button'
import {observer} from "mobx-react-lite";
import {useRootServices} from "../../services";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {getForm} from "../../forms";


const Login = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState<"SignIn" | "SignUp">("SignIn");
  const [error, setError] = useState("");
  const { authService, workspaceService: {isWorkspaceInitialized} } = useRootServices()

  useEffect(() => {
    if (authService.isLoggedIn && isWorkspaceInitialized) navigate("/api")
  }, [authService.isLoggedIn, isWorkspaceInitialized])


  const schema = yup.object({
    email: yup.string()
        .test('is-not-empty', 'Email name must not be empty', (value) => value !== '')
        .required('Email is required'),
    password: yup.string()
        .test('is-not-empty', 'Password must not be empty', (value) => value !== '')
        .required('Password is required'),
  })

  const formik = getForm({email: '', password: ''}, schema)({
    onSubmit: async (_) => {
      try {
        if (tab === "SignIn")
          await authService.loginWithEmail(formik.values.email, formik.values.password)
        else
          await authService.signUpWithEmail(formik.values.email, formik.values.password)
      } catch (errorMessage) {
        setError(errorMessage as string)
      }
    }
  })

  return (
    <Pane display="flex" width="100%" height="100vh" background="tint2" justifyContent="center" alignItems="center">
      <Pane width={500} height="fit-content" border="default" borderRadius={5} padding={40} background="white">
        <Heading size={900} textAlign="center">Welcome</Heading>
        <GoogleButton style={{ width: "100%", marginTop: 20 }}
            onClick={() => authService.signInWithGoogle()}
        />
        <Heading size={500} margin={20} textAlign="center">OR</Heading>
        <Tablist display="flex" justifyContent="center" margin={20}>
          <Tab
              isSelected={tab === "SignIn"}
              onClick={() => setTab("SignIn")}
          >
            Sign In
          </Tab>
          <Tab
              isSelected={tab === "SignUp"}
              onClick={() => setTab("SignUp")}
          >
            Sign Up
          </Tab>
        </Tablist>
        <Pane display="flex" flexDirection="column">
          <TextInputField
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
          />
          <TextInputField
              label="Password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
          />
          <Button
              appearance="primary"
              width="100%"
              marginTop={10}
              onClick={() => formik.handleSubmit()}
          >
            {tab === "SignIn" ? "Sign In": "Sign Up"}
          </Button>
          {
            error && <Text textAlign="center" color="red" marginTop={10}>{error}</Text>
          }
        </Pane>
      </Pane>
    </Pane>
  );
}

export default observer(Login);
