import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCkAC5qgH3Pwuh6bownBvv808wOeMu65Lc",
    authDomain: "nocotuneai.firebaseapp.com",
    databaseURL: "https://nocotuneai-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nocotuneai",
    storageBucket: "nocotuneai.appspot.com",
    messagingSenderId: "270737209896",
    appId: "1:270737209896:web:487b75daf8a8de7d78d31d",
    measurementId: "G-S56KT95FTF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth(app);

export {
    analytics,
    database,
    auth,
}
