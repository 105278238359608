import {
    observable,
    action,
    makeAutoObservable,
    runInAction, autorun, computed,
} from 'mobx'
import {ApiService, API} from "../client";
import WorkspaceService from "./workspace.service";
import AuthService from "./auth.service";
import {onValue, ref} from "firebase/database";
import {database} from "../utils/firebase";
import {toaster} from "evergreen-ui";


type APIUsage = {
    requestCount: number,
}

export default class APIService {
    @observable apis = observable.map<string, API>()
    @observable activeApiId?: string
    @observable apiUsage = observable.map<string, APIUsage>()
    @observable fetchApiSubscription?: () => void

    constructor(private authService: AuthService, private workspaceService: WorkspaceService) {
        makeAutoObservable(this)
        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetch())
        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetchAPIUsage())
    }

    @computed get apisArray(): API[] {
        return Array.from(this.apis.values())
    }

    @computed get activeApi(): API | undefined {
        return this.apis.get(this.activeApiId || '')
    }

    @action setActiveApi = (apiId?: string) => this.activeApiId = apiId

    @action fetchAPIUsage = async () => {
        if (this.fetchApiSubscription) this.fetchApiSubscription()

        const itemRef = ref(database, `analytics/${this.workspaceService.activeWorkspaceId}/apiUsage`)
        this.fetchApiSubscription = onValue(itemRef, (snapshot) => {
            const item = snapshot.val() as {[apiId: string]: APIUsage}
            runInAction(() => {
                this.apiUsage.replace(item)
            })
        });
    }

    @action fetch = async () => {
        try {
            const apis = await ApiService.get()
            runInAction(() => {
                this.apis.replace(apis.reduce((map, template) => {
                    map.set(template.id, template)
                    return map
                }, observable.map<string, API>()))
            })
        } catch (err) {
        }
    }

    @action upsert = async (name: string, template_id: string, model_id: string, id?: string) => {
        try {
            const template = await ApiService.upsert({
                name, template_id, model_id, id,
            })
            runInAction(() => {
                this.apis.set(template.id, template)
            })
            toaster.success("API saved successfully")
        } catch (err) {
        }
    }

    @action delete = async (apiId: string) => {
        try {
            await ApiService.delete(apiId)
            runInAction(() => {
                this.apis.delete(apiId)
            })
            toaster.success("API deleted successfully")
        } catch (err) {
            
        }
    }
}
