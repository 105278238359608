import userAccountSchema from './userAccountSchema';
import templateSchema from './templateSchema';
import workspaceSchema, {workspaceSchemaWithOpenAIKey} from './workspaceSchema';
import apiSchema from './apiSchema';
import modelSchema from "./modelSchema";

import { FormikValues, useFormik } from "formik";
import * as yup from "yup";


const getForm = <T extends FormikValues>(defaultValues: T, schema: any | (() => any)) => (
    ({onSubmit, initialValues}: {onSubmit: (values: T) => void, initialValues?: T}) => (
        useFormik<T>({
            initialValues: initialValues || defaultValues,
            validationSchema: schema,
            onSubmit: onSubmit,
            validateOnMount: true,
            enableReinitialize: true,
        })
    )
)

const useTemplateForms = getForm({name: '', template: '', id: undefined as undefined | string}, templateSchema)
const useModelForms = getForm({name: '', base_model_id: '', template_id: '', id: undefined as undefined | string}, modelSchema)
const useUserAccountForms = getForm({name: '', company: ''}, userAccountSchema)
const useWorkspaceForms = getForm({name: '', openai_api_key: ''}, workspaceSchema)
const useWorkspaceFormsWithOpenAIKey = getForm({name: '', openai_api_key: ''}, workspaceSchemaWithOpenAIKey)
const useApiSchemaForms = getForm({
    name: "",
    template_id: "",
    model_id: "",
    id: undefined,
}, apiSchema)

export {
    getForm,
    useTemplateForms,
    useModelForms,
    useUserAccountForms,
    useWorkspaceFormsWithOpenAIKey,
    useWorkspaceForms,
    useApiSchemaForms,
}

