import React, {useCallback, useEffect, useState} from "react";
import {
  Dialog,
  Pane,
  Button,
  TextInputField,
  Tablist,
  Tab,
  Heading, Text, Link,
} from "evergreen-ui";
import GoogleButton from 'react-google-button'
import { observer } from "mobx-react-lite";
import { useRootServices } from "../../services";
import {useNavigate, useSearchParams} from "react-router-dom";


const Workspace = () => {
  const navigate = useNavigate();

  const { workspaceService } = useRootServices()

  const [newWorkspace, setNewWorkspace] = useState({name: "", openai_api_key: ""})
  const submitNewWorkspace = () => workspaceService.create(
      newWorkspace.name, newWorkspace.openai_api_key || undefined,
  ).then(() => {
    navigate(`/workspace/${newWorkspace.name}`)
  })

  return (
      <Pane display="flex" width="100%" height="100vh" background="tint2" justifyContent="center" alignItems="center">
        <Pane width={500} height="fit-content" border="default" borderRadius={5} padding={40} background="white">
          <Heading size={900} textAlign="center" marginBottom={20}>Workspace Setup</Heading>
          <TextInputField
            label="Workspace Name"
            required={true}
            onChange={(e: any) => setNewWorkspace({...newWorkspace, name: e.target.value})}
          />
          <TextInputField
              width="100%"
              label="OpenAI API Key (Optional)"
              type="password"
              hint={
                <Text>
                  Click{" "}
                  <Link target="_blank" href="https://beta.openai.com/account/api-keys">
                    here
                  </Link>{" "}
                  to get your API key.
                </Text>
              }
              onChange={(e: any) => setNewWorkspace({...newWorkspace, openai_api_key: e.target.value})}
              placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          />
          <Button
            appearance="primary"
            width="100%"
            marginTop={10}
            disabled={newWorkspace.name === ""}
            onClick={() => submitNewWorkspace()}
          >
            Get Started
          </Button>
        </Pane>
      </Pane>
  );
}

export default observer(Workspace);
