/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserAccount } from '../models/UserAccount';
import type { UserAccountRequest } from '../models/UserAccountRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAccountService {

    /**
     * Get
     * @param authorization
     * @returns UserAccount Successful Response
     * @throws ApiError
     */
    public static get(
        authorization?: string,
    ): CancelablePromise<UserAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user_account/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * @param requestBody
     * @param authorization
     * @returns UserAccount Successful Response
     * @throws ApiError
     */
    public static update(
        requestBody: UserAccountRequest,
        authorization?: string,
    ): CancelablePromise<UserAccount> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user_account/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @param authorization
     * @returns UserAccount Successful Response
     * @throws ApiError
     */
    public static create(
        requestBody: UserAccountRequest,
        authorization?: string,
    ): CancelablePromise<UserAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user_account/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

}
