import {
    computed,
    observable,
    action,
    makeAutoObservable,
    runInAction, autorun,
} from 'mobx'
import {
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import type { User, IdTokenResult } from "firebase/auth";
import { auth } from '../utils/firebase'
import {OpenAPI} from "../client";
import {persist} from "mobx-persist";
import {addApiResultInterceptor, Interceptor} from "../utils/httpInterceptor";
import {toaster} from "evergreen-ui";


export default class AuthService {
    @persist('object') @observable user?: User
    @persist('object') @observable token?: IdTokenResult
    @observable isInitialized: boolean = false

    constructor() {
        makeAutoObservable(this)
        onAuthStateChanged(auth, user => runInAction(() => {
            if (user) {
                this.user = user
                this.updateToken()
            } else {
                this.user = undefined
                this.token = undefined
                OpenAPI.TOKEN = undefined
            }
            this.isInitialized = true
        }));
        addApiResultInterceptor(this.handleUnauthorized.bind(this))
        autorun(() => this.user && this.setBugPilot(this.user))
    }

    setBugPilot(user: User) {
        try {
            // @ts-ignore
            window.Bugpilot.identify({
                id: user.uid, // Required
                email: user.email, // Required
                firstName: user.displayName,
                emailVerified: user.emailVerified,
                providerId: user.providerId,
            });
        } catch (e) {
            console.error(e)
        }
    }

    @action updateToken = async () => {
        const token = await this.user?.getIdTokenResult(true)
        runInAction(() => {
            this.token = token
            OpenAPI.TOKEN = this.token?.token
        })
    }

    @computed
    get userId() {
        return this.user?.uid
    }

    @computed
    get isLoggedIn() {
        return !!this.user && !!this.token
    }

    @action
    loginWithEmail = async (email: string, password: string) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            runInAction(() => {
                this.user = userCredential.user
            });
            toaster.success("Logged in successfully")
            return Promise.resolve(userCredential.user)
        } catch (error) {
            let errorMessage = 'Unknown error';
            if ((error as any).code === 'auth/user-not-found') {
                errorMessage = 'User not found';
            }
            if ((error as any).code === 'auth/wrong-password') {
                errorMessage = 'Wrong password';
            }
            if ((error as any).code === 'auth/user-disabled') {
                errorMessage = 'User disabled';
            }
            if ((error as any).code === 'auth/invalid-email') {
                errorMessage = 'Invalid email';
            }
            toaster.danger(errorMessage);
            return Promise.reject(errorMessage)
        }
    };

    @action
    signUpWithEmail = async (email: string, password: string) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            runInAction(() => {
                this.user = userCredential.user
            });
            toaster.success("Account created successfully")
            return Promise.resolve(userCredential.user)
        } catch (error) {
            let errorMessage = 'Unknown error';
            if ((error as any).code === 'auth/email-already-in-use') {
                errorMessage = 'Email already in use';
            }
            if ((error as any).code === 'auth/invalid-email') {
                errorMessage = 'Invalid email';
            }
            if ((error as any).code === 'auth/weak-password') {
                errorMessage = 'Weak password';
            }
            toaster.danger(errorMessage);
            return Promise.reject(errorMessage)
        }
    }

    @action
    signInWithGoogle = async (callback = (_: User)=>{}) => {
        const provider = new GoogleAuthProvider()
        const result = await signInWithPopup(auth, provider)
        runInAction(() => {
            this.user = result.user
            callback(this.user)
        });
        toaster.success("Logged in successfully")
    };

    @action
    signOut = async () => {
        await signOut(auth)
        runInAction(() => {
            this.user = undefined
            this.token = undefined
            OpenAPI.TOKEN = undefined
        })
        toaster.success("Logged out successfully")
    }

    handleUnauthorized: Interceptor = async (result) => {
        if (result.status === 401) {
            await this.updateToken()
            toaster.danger('Your session had expired. Please try again')
        }
    }
}
