import {observer} from "mobx-react-lite";
import {useRootServices} from "../services";
import React, {useState} from "react";
import {getForm} from "../forms";
import * as yup from "yup";
import {Dialog, DuplicateIcon, Pane, Text, TextInputField, toaster, Tooltip} from "evergreen-ui";


const WorkspaceAPIKeyDialog = ({
  show,
  setShow,
  deleteSelection,
  setDeleteSelection,
}: {
  show: boolean,
  setShow: (show: boolean) => void,
  deleteSelection?: string,
  setDeleteSelection?: (deleteSelection?: string) => void
}) => {

  const { workspaceService } = useRootServices()

  const [resultKeyDialog, setResultKeyDialog] = useState<string | undefined>(undefined)
  const selectedKey = workspaceService.activeWorkspace?.workspace_api_keys?.[deleteSelection || '']

  const forum = getForm({name: ''}, yup.object({
    name: yup.string()
        .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
        .required('Name is required'),
  }))({
    onSubmit: (values) => {
      workspaceService.generateAPIKey(values.name).then(() => {
        setShow(false)
        setResultKeyDialog(values.name)
      })
    }
  })

  return (
      <>
        <Dialog
            isShown={show}
            title="API key generated"
            confirmLabel="Generate Key"
            isConfirmDisabled={!forum.isValid}
            onConfirm={() => forum.handleSubmit()}
            onCloseComplete={() => setShow(false)}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <TextInputField
                name="name"
                label="Name your API key"
                placeholder="Example: Sample API key"
                value={forum.values.name}
                onChange={forum.handleChange}
                validationMessage={(forum.touched.name && forum.errors.name)}
            />
          </Pane>
        </Dialog>
        <Dialog
            isShown={resultKeyDialog !== undefined}
            title="API key generated"
            hasFooter={false}
            onCloseComplete={() => setResultKeyDialog(undefined)}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <Text size={400}>
              Please save this secret key somewhere safe and accessible. For
              security reasons, you won't be able to view it again through your
              account. If you lose this secret key, you'll need to generate a new
              one.
            </Text>
            <Pane
                display="flex"
                border="default"
                padding={10}
                borderRadius={5}
                justifyContent="space-between"
                marginBottom={50}
            >
              <Text>{workspaceService.getApiKey(resultKeyDialog as string)?.key}</Text>
              <Tooltip content="Copy!">
                <DuplicateIcon onClick={() => {
                  navigator.clipboard.writeText(workspaceService.getApiKey(resultKeyDialog as string)?.key || "")
                  toaster.success('Copied to clipboard')
                }} />
              </Tooltip>
            </Pane>
          </Pane>
        </Dialog>
        <Dialog
            isShown={deleteSelection !== undefined}
            title="Revoke API key"
            intent="danger"
            confirmLabel="Delete"
            onCloseComplete={() => setDeleteSelection && setDeleteSelection(undefined)}
            onConfirm={() => deleteSelection && workspaceService.removeApiKey(deleteSelection).then(
              () => setDeleteSelection && setDeleteSelection(undefined)
            )}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <Text size={400}>
              This API key will immediately be disabled. API requests made using
              this key will be rejected, which could cause any systems still
              depending on it to break. Once revoked, you'll no longer be able to
              view or modify this API key.
            </Text>
            <Pane
                display="flex"
                border="default"
                padding={10}
                borderRadius={5}
                justifyContent="center"
                marginBottom={50}
            >
              <Text>{selectedKey?.key}</Text>
            </Pane>
          </Pane>
        </Dialog>
      </>
  );
}


export default observer(WorkspaceAPIKeyDialog);