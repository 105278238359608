import React, {useCallback, useEffect, useState} from "react";
import {
  PlusIcon,
  DuplicateIcon,
  DeleteIcon,
  PlayIcon,
  Dialog,
  Pane,
  Text,
  Heading,
  Button,
  TextareaField,
  TextInputField, Tooltip,
} from "evergreen-ui";
import {useRootServices} from "../../services";
import {observer} from "mobx-react-lite";
import {timeAgo, timeAgoString} from "../../utils";
import {useNavigate} from "react-router-dom";


const TemplatePage = () => {
  const navigate = useNavigate();

  const [saveTemplateDialog, setSaveTemplateDialog] = useState<boolean>();
  const [deleteItem, setDeleteItem] = useState<string | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>("");

  const { templateService } = useRootServices()

  const [templateName, setTemplateName] = useState<string>('')

  const newTemplate = () => {
    setTemplateName("")
    setSaveTemplateDialog(true)
  }

  const saveTemplate = () => {
    templateService.upsert(templateName, "").then((template) => {
      setSaveTemplateDialog(false)
      navigate(`/templates/editor?template=${template.id}`)
    })
  }

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="right">
        <Pane marginLeft={20}>
          <Button
            appearance="primary"
            iconAfter={PlusIcon}
            onClick={() => newTemplate()}
          >
            New Template
          </Button>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        padding={16}
        flexDirection="column"
        alignItems="center"
        gap={10}
      >
        {
          templateService.templatesArray.map((template) => {
            return (
                <Pane
                    key={template.id}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    border="default"
                    padding={15}
                    borderRadius={5}
                    alignItems="center"
                    onClick={() => navigate(`/templates/editor?template=${template.id}`)}
                >
                  <Pane display="flex" flexDirection="column">
                    <Heading size={500}>{template.name}</Heading>
                    <Text>Added {timeAgoString(template.created_at as string)}</Text>
                  </Pane>
                  <Pane display="flex" gap={10}>
                    <Tooltip content="Duplicate">
                      <DuplicateIcon color="gray700" onClick={(event) => {
                        setTemplateName(`${template.name} (copy)`)
                        setSaveTemplateDialog(true)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                    <Tooltip content="Try Out">
                      <PlayIcon color="gray700" onClick={(event) => {
                        navigate(`/run?template=${template.id}`)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                    <Tooltip content="Delete">
                      <DeleteIcon color="red500" onClick={(event) => {
                        setDeleteItem(template.id)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                  </Pane>
                </Pane>
            )
          })
        }
      </Pane>
      <Dialog
        isShown={saveTemplateDialog}
        title="Create Template"
        confirmLabel="Continue"
        onCloseComplete={() => setSaveTemplateDialog(false)}
        isConfirmDisabled={templateName.length === 0}
        onConfirm={saveTemplate}
      >
        <TextInputField
          label="Name"
          description="Give your template a name"
          value={templateName}
          onChange={(event: any) => setTemplateName(event.target.value)}
        />
      </Dialog>
      <Dialog
          isShown={!!deleteItem}
          title={`Delete Confirmation`}
          intent="danger"
          confirmLabel="Delete"
          onCloseComplete={() => {
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
          isConfirmDisabled={deleteConfirmation !== templateService.templates.get(deleteItem||"")?.name}
          onConfirm={() => {
            templateService.delete(deleteItem as string)
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
      >
        <TextInputField
            label={`Are Your Sure you want to Delete ${templateService.templates.get(deleteItem || "")?.name}?`}
            description="Please type in full template name to confirm deletion"
            placeholder={templateService.templates.get(deleteItem||"")?.name}
            value={deleteConfirmation}
            onChange={(e: any)=>setDeleteConfirmation(e.target.value)}
        />
      </Dialog>
    </Pane>
  );
};

export default observer(TemplatePage);
