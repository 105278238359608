import {observer} from "mobx-react-lite";
import {useRootServices} from "../services";
import {Button, CogIcon, Label, Pane, Popover, TagInput, TextInputField} from "evergreen-ui";
import React from "react";


const OpenAISettingsPopover = ({text}: {text?: string}) => {
  const { openAIService } = useRootServices()
  return (
      <Popover content={(
          <Pane
              width={600}
              padding={30}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              flexWrap="wrap"
          >
            <TextInputField
                width="49%"
                label="Temperature"
                placeholder="Enter value"
                type="number"
                min={0}
                max={1}
                step={0.1}
                value={openAIService.openaiParams.get("temperature")}
                onChange={(e: any) => openAIService.setOpenAIParams("temperature", e.target.value)}
            />
            <TextInputField
                width="49%"
                label="Max Tokens"
                placeholder="Enter value"
                type="number"
                min={0}
                max={4000}
                step={1}
                value={openAIService.openaiParams.get("max_tokens")}
                onChange={(e: any) => openAIService.setOpenAIParams("max_tokens", e.target.value)}
            />
            <TextInputField
                width="49%"
                label="Top P"
                placeholder="Enter value"
                type="number"
                min={0}
                max={1}
                step={0.1}
                value={openAIService.openaiParams.get("top_p")}
                onChange={(e: any) => openAIService.setOpenAIParams("top_p", e.target.value)}
            />
            <TextInputField
                width="49%"
                label="Frequency Penalty"
                placeholder="Enter value"
                type="number"
                min={-2}
                max={2}
                step={0.1}
                value={openAIService.openaiParams.get("frequency_penalty")}
                onChange={(e: any) => openAIService.setOpenAIParams("frequency_penalty", e.target.value)}
            />
            <TextInputField
                width="49%"
                label="Presence Penalty"
                placeholder="Enter value"
                type="number"
                min={-2}
                max={2}
                step={0.1}
                value={openAIService.openaiParams.get("presence_penalty")}
                onChange={(e: any) => openAIService.setOpenAIParams("presence_penalty", e.target.value)}
            />
            <Pane display="flex" flexDirection="column" width="100%">
              <Label htmlFor="stop-words" display="block" marginBottom={4}>Stop Words</Label>
              <TagInput
                  marginTop={5}
                  inputProps={{
                    placeholder: "Enter stop words",
                    width: "100%",
                  }}
                  values={openAIService.openaiParams.get("stop") as string[]}
                  onChange={(values) => {
                    openAIService.setOpenAIParams("stop", [...values])
                  }}
              />
            </Pane>
          </Pane>
      )}>
        <Button appearance="minimal" iconBefore={CogIcon}>{text || "OpenAI"}</Button>
      </Popover>
  )
}

export default observer(OpenAISettingsPopover)