import React from 'react'
import { create } from 'mobx-persist'
import { observable } from 'mobx'
import {OpenAPI} from "../client";
import AuthService from "./auth.service";
import WorkspaceService from "./workspace.service";
import TemplateService from "./template.service";
import ModelsService from "./models.service";
import AccountService from "./account.service";
import OpenAIService from "./open.service";
import APIService from "./api.service";

const hydrate = create({jsonify: true})

export {
    AuthService
}


export interface RootServiceContextValue {
    authService: AuthService;
    accountService: AccountService;
    workspaceService: WorkspaceService;
    templateService: TemplateService;
    modelsService: ModelsService;
    openAIService: OpenAIService;
    apiService: APIService;
}

export const initServices = (): RootServiceContextValue => {
    OpenAPI.BASE = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.nocoai.io'

    const authService = new AuthService();
    const accountService = new AccountService(authService);
    const workspaceService = new WorkspaceService(authService);
    const apiService = new APIService(authService, workspaceService);
    const templateService = new TemplateService(authService, workspaceService);
    const modelsService = new ModelsService(authService, workspaceService, templateService);
    const openAIService = new OpenAIService(modelsService, templateService, workspaceService);


    Promise.all([
        hydrate('auth.service', AuthService),
    ])

    return observable({
        authService,
        accountService,
        workspaceService,
        apiService,
        templateService,
        modelsService,
        openAIService,
    })
}

export const RootServiceContext = React.createContext({} as RootServiceContextValue)

export const RootServiceContextProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const services = initServices()
    return (
        <RootServiceContext.Provider value={services}>
            {children}
        </RootServiceContext.Provider>
    )
}

export const useRootServices = () => React.useContext(RootServiceContext)
