/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FineTuneTaskType {
    CREATE_FILE = 'create_file',
    UPLOAD_FILE = 'upload_file',
    CREATE_MODEL_REQUEST = 'create_model_request',
    POLL_MODEL_REQUEST = 'poll_model_request',
}
