import React from "react";
import {Button, Heading, Pane, RefreshIcon, TextInputField,} from "evergreen-ui";

import {useUserAccountForms} from "../../forms";
import {observer} from "mobx-react-lite";
import {useRootServices} from "../../services";

const UserAccount = () => {
  const { accountService } = useRootServices()
  const userAccountForm = useUserAccountForms({
    onSubmit: (values) => {
      const { name, company } = values;
      accountService.update(name, {company});
    },
    initialValues: {
      name: accountService.userAccount?.name || "",
      company: accountService.userAccount?.attributes?.company || ""
    },
  });

  return (
    <Pane width="100%" display="flex" padding={16} flexDirection="column">
      <TextInputField
          name="name"
          label="Username"
          placeholder="Example: Rameez"
          value={userAccountForm.values.name}
          onChange={userAccountForm.handleChange}
          validationMessage={userAccountForm.touched.name && userAccountForm.errors.name}
      />
      <TextInputField
          name="company"
          label="Company"
          placeholder="Example: NocoAI"
          value={userAccountForm.values.company}
          onChange={userAccountForm.handleChange}
          validationMessage={userAccountForm.touched.company && userAccountForm.errors.company}
      />
      <Button
          alignSelf="flex-end"
          appearance="primary"
          iconBefore={RefreshIcon}
          onClick={()=>userAccountForm.handleSubmit()}
      >
        Save
      </Button>
    </Pane>
  );
};

export default observer(UserAccount);
