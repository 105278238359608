import React, {useEffect, useState} from "react";
import {
  Button,
  Heading,
  EditIcon,
  MaximizeIcon,
  RefreshIcon,
  Pane,
  PlayIcon,
  StopIcon,
  ResetIcon,
  TextareaField,
  TextInputField,
  Tooltip,
  SelectMenu,
} from "evergreen-ui";
import {useRootServices} from "../../services";
import {getForm, useTemplateForms} from "../../forms";
import * as yup from "yup";
import {observer} from "mobx-react-lite";
import {useNavigate, useLocation} from "react-router-dom";
import 'react-rangeslider/lib/index.css'
import {OpenAISettingsPopover} from "../../dialogs";
import OpenAIAPIKeyDialog from "../../dialogs/openAIAPIKeyDialog";

const Run = () => {
  const navigate = useNavigate();
  const location = useLocation();


  const { openAIService, modelsService, workspaceService: {activeWorkspace}, templateService } = useRootServices()
  const [ expandItem, setExpandItem ] = useState<{[key: string]: boolean}>({});
  const [ showOpenAPIDialog, setShowOpenAPIDialog ] = useState<boolean>(false);

  const {outputModelOptions, setSelectedOutputModel, selectedOutputModel} = modelsService

  const templateNameRef = React.useRef<HTMLHeadingElement>(null)
  const template = templateService.templates.get(new URLSearchParams(location.search).get('template') || '')
  const templateForum = useTemplateForms({
    onSubmit: ({name, template, id}) => {
      name = templateNameRef.current?.innerText || name
      console.log(name)
      templateService.upsert(name, template, id)
    },
    initialValues: {
      name: template?.name || '', template: template?.template || '', id: template?.id
    }
  })

  const params = templateService.getParamsFromData(templateForum.values.template)

  const schema = yup.object(params.reduce((acc, param) => {
    acc[param] = yup.string().required(`${param} is required`); return acc
  }, {} as {[key: string]: yup.StringSchema}))
  const defaults = params.reduce((acc, param) => {
    acc[param] = ''; return acc
  }, {} as {[key: string]: string})

  const formik = getForm(defaults, schema)({
    onSubmit: (values) => {
      openAIService.generate(templateForum.values.template, values)
    },
  })

  useEffect(() => {
    setShowOpenAPIDialog(!activeWorkspace?.openai_api_key?.key)
  }, [showOpenAPIDialog, activeWorkspace])

  return (
    <Pane display="flex" flexDirection="column">
      <Pane padding={16} display="flex" justifyContent="space-between" alignItems="center">
        <Pane display="flex" flexDirection="row" alignItems="center" gap={10}>
          <Heading
              size={800}
              ref={templateNameRef}
              contentEditable
          >
            {templateForum.values.name}
          </Heading>
          <EditIcon size={20} onClick={() => templateNameRef.current?.focus()}/>
        </Pane>
        <Pane display="flex" gap={10} alignSelf="flex-end">
          <Button
              appearance="primary"
              width="fit-content"
              alignSelf="flex-end"
              iconBefore={RefreshIcon}
              onClick={() => templateForum.handleSubmit()}
          >
            Save Template
          </Button>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        margin={16}
        flexDirection="column"
        borderRadius={3}
      >
        <TextareaField
            name="template"
            label={<Heading size={600}>Content</Heading>}
            description="Define your template here and use {{var}} for variables."
            inputHeight={150}
            placeholder="Example: Hello {{name}}"
            value={templateForum.values.template}
            onChange={templateForum.handleChange}
            validationMessage={templateForum.touched.template && templateForum.errors.template}
        />
      </Pane>
      <Pane display="flex" padding={16} flexDirection="row" gap={15}>
        <Pane display="flex" flex={1} flexDirection="column" gap={10}>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading size={600}>Parameters</Heading>
            <Pane display="flex" gap={5} flexDirection="row">
              <OpenAISettingsPopover />
              <Button
                  appearance="minimal"
                  iconBefore={ResetIcon}
                  onClick={()=> formik.resetForm()}
              >
                Reset
              </Button>
              {
                openAIService.isRunning? (
                  <Button
                      iconBefore={StopIcon}
                      onClick={() => openAIService.abort()}
                  >
                    Stop
                  </Button>
                ): (
                  <Button
                      iconBefore={PlayIcon}
                      onClick={() => formik.handleSubmit()}
                      disabled={!formik.isValid}
                  >
                    Run
                  </Button>
                )
              }
            </Pane>
          </Pane>
          <Pane
            display="flex"
            border="default"
            flexDirection="column"
            padding={15}
            borderRadius={5}
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {
                params.map(param => {
                  const Component = expandItem[param] ? TextareaField : TextInputField
                  return (
                    <Pane display="flex" alignItems={"center"} gap={5} key={param}>
                      <Component
                          flex={1}
                          name={param}
                          label={param}
                          placeholder="Enter value"
                          value={formik.values[param]}
                          onChange={formik.handleChange}
                          validationMessage={formik.touched[param] && formik.errors[param]}
                      />
                      <Tooltip content={expandItem[param] ? "Collapse" : "Expand"}>
                        <MaximizeIcon size={10} onClick={()=>{
                          setExpandItem({...expandItem, [param]: !expandItem[param]})
                        }} alignSelf="flex-start"/>
                      </Tooltip>
                    </Pane>
                  )
                })
            }
          </Pane>
        </Pane>
        <Pane display="flex" flex={2} flexDirection="column" gap={10}>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading size={600}>Output</Heading>
            <Pane display="flex" gap={5} alignItems="center">
              <SelectMenu
                  title="Select Model To use only for Output"
                  options={outputModelOptions}
                  onSelect={changedItem => setSelectedOutputModel(changedItem.value as string)}
                  selected={selectedOutputModel || undefined}
              >
                <Button>{selectedOutputModel || 'Select Model To Use'}</Button>
              </SelectMenu>
            </Pane>
          </Pane>
          <Pane display="flex" border="default" padding={15} borderRadius={5}>
            <TextareaField
              width="100%"
              inputHeight={300}
              label="Open AI Result"
              description="Update the results and and train to fine-tune the model"
              placeholder="Result"
              value={openAIService.promptResult}
              onChange={(e: any) => openAIService.setPromptResult(e.target.value)}
            />
          </Pane>
        </Pane>
      </Pane>
      <OpenAIAPIKeyDialog
          show={showOpenAPIDialog}
          setShow={setShowOpenAPIDialog}
          onCanceled={() => navigate("/templates")}
      />
    </Pane>
  );
};

export default observer(Run);
