/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ClientIdTokenType {
    FIREBASE = 'firebase',
    AUTH0 = 'auth0',
    CUSTOM = 'custom',
}
