import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { decodeProtectedHeader, importX509, jwtVerify }  from "jose";

TimeAgo.addDefaultLocale(en)

export const timeAgo = new TimeAgo('en-US')

export const timeAgoString = (date: string) => {
    return timeAgo.format(new Date(date))
}

export const createdAtDisplay = (date?: string): string => {
    return date? new Date(date).toDateString(): "NA"
}

export const uuidv4 = () => {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

// @ts-ignore
export const objectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))


export async function verifyIdToken(idToken: string) {
    //Fetch public keys
    //TODO: Public keys should be cached until they expire
    const res = await fetch(
        "https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com"
    );
    const data = await res.json();
    //Get the correct publicKey from the key id
    const header = decodeProtectedHeader(idToken);

    const certificate = data[header.kid as string];
    const publicKey = await importX509(certificate, "RS256");

    const { payload } = await jwtVerify(idToken, publicKey);

    return payload;
}
