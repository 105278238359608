import React, {useEffect} from "react";
import {Menu, MenuItem, Sidebar, useProSidebar} from "react-pro-sidebar";
import {Link, useNavigate} from "react-router-dom";
import {
  Avatar,
  CubeIcon,
  Dialog,
  Heading,
  Image,
  LinkIcon,
  Menu as EVMenu,
  Pane,
  Popover,
  Position,
  Select,
  Text,
  TextInputField,
  WidgetIcon,
} from "evergreen-ui";
import {observer} from "mobx-react-lite";
import {useRootServices} from "./services";
import {useUserAccountForms} from "./forms";
import PageLoading from "./PageLoading";

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = observer(({ title }) => {
  const navigate = useNavigate();

  const {
    workspaceService: { workspacesArray, selectedActiveWorkspace, activeWorkspaceId },
    authService: { signOut },
    accountService: { userAccount }
  } = useRootServices()

  return (
      <Pane
          display="flex"
          background="tint2"
          padding={20}
          border="default"
          alignItems="center"
          justifyContent="space-between"
      >
        <Heading size={800}>{title}</Heading>
        <Pane display="flex" alignItems="center" gap={50}>
          <Select
            width={200}
            value={activeWorkspaceId}
            onChange={event => selectedActiveWorkspace(event.target.value)}
          >
            {
              workspacesArray.map((workspace) => (
                <option key={workspace.id} value={workspace.id} selected={workspace.id === activeWorkspaceId}>
                  {workspace.name}
                </option>
              ))
            }
          </Select>
          <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <EVMenu>
                  <EVMenu.Group>
                    <EVMenu.Item onSelect={() => navigate("/user-account")}>
                      User Account
                    </EVMenu.Item>
                    <EVMenu.Item onSelect={() => navigate("/workspace-admin")}>
                      Workspace Admin
                    </EVMenu.Item>
                  </EVMenu.Group>
                  <EVMenu.Divider/>
                  <EVMenu.Group>
                    <EVMenu.Item
                        onSelect={() => signOut()}
                        intent="danger"
                    >
                      Log out
                    </EVMenu.Item>
                  </EVMenu.Group>
                </EVMenu>
              }
          >
            <Avatar name={userAccount?.name} size={40}/>
          </Popover>
        </Pane>
      </Pane>
  )
});

const MenuContent: React.FC<{ name: string; icon: React.ReactNode }> = ({
  name,
  icon
}) => (
  <Pane display="flex" alignItems="center" gap={10}>
    {icon}
    <Text size={400}>{name}</Text>
  </Pane>
);

type LayoutProps = {
  title: string;
  children: React.ReactNode;
};


const ProfileSetupModal: React.FC = observer(() => {
  const { accountService } = useRootServices();
  const showProfileDialog = accountService.isSetupRequired === true;

  const userAccountForm = useUserAccountForms({
    onSubmit: (values) => {
      const { name, company } = values;
      accountService.create(name, {company});
    },
  });

  return (
      <Dialog
          isShown={showProfileDialog}
          title="Setup Profile"
          confirmLabel="Save"
          hasClose={false}
          hasCancel={false}
          isConfirmDisabled={!userAccountForm.isValid}
          onConfirm={() => userAccountForm.handleSubmit()}
      >
        <TextInputField
            name="name"
            label="Username"
            value={userAccountForm.values.name}
            onChange={userAccountForm.handleChange}
            validationMessage={userAccountForm.touched.name && userAccountForm.errors.name}
        />
        <TextInputField
            name="company"
            label="Company"
            value={userAccountForm.values.company}
            onChange={userAccountForm.handleChange}
            validationMessage={userAccountForm.touched.company && userAccountForm.errors.company}
        />
      </Dialog>
  )
});

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const navigate = useNavigate()
  const { collapseSidebar } = useProSidebar();

  const { workspaceService } = useRootServices()

  useEffect(() => {
    if (workspaceService.isWorkspaceInitialized && workspaceService.workspacesArray.length === 0) navigate("/workspace")
  }, [workspaceService.isWorkspaceInitialized, workspaceService.workspacesArray.length, navigate])

  if (!workspaceService.isWorkspaceInitialized) return <PageLoading />

  return (
    <Pane display="flex">
      <Sidebar style={{ height: "100vh" }}>
        <Pane padding={25}>
          <Image onClick={() => navigate("/")} src="/images/logo-full.png" height={30}/>
        </Pane>
        <Menu>
          <MenuItem routerLink={<Link to="/api" />}>
            <MenuContent name="APIs" icon={<LinkIcon />} />
          </MenuItem>
          <MenuItem routerLink={<Link to="/templates" />}>
            <MenuContent name="Templates" icon={<WidgetIcon />} />
          </MenuItem>
          <MenuItem routerLink={<Link to="/models" />}>
            <MenuContent name="Models" icon={<CubeIcon />} />
          </MenuItem>
        </Menu>
      </Sidebar>
      <Pane>

      </Pane>
      <Pane display="flex" flexDirection="column" width="100%" height="calc(100vh - 50px)" overflowY="scroll">
        <Header title={title} />
        <Pane width="100%" height="calc(100vh - 50px)" overflowY="scroll">
          {children}
        </Pane>
      </Pane>
      <ProfileSetupModal />
    </Pane>
  );
};

export default Layout;
