import {
    observable,
    action,
    computed,
    makeAutoObservable,
    runInAction, autorun,
} from 'mobx'
import {
    OpenAPI,
    WorkspaceApiService,
    Workspace,
    ClientAuthorizationRequest,
    APIKey,
} from "../client";
import AuthService from "./auth.service";
import {toaster} from "evergreen-ui";


export default class WorkspaceService {
    @observable workspaces = observable.map<string, Workspace>()
    @observable activeWorkspaceId?: string
    @observable isWorkspaceInitialized: boolean = false

    constructor(private authService: AuthService) {
        makeAutoObservable(this)

        autorun(() => this.authService.isLoggedIn && this.fetch())
    }

    public getApiKey = (name: string): APIKey | undefined => {
        return this.activeWorkspace?.workspace_api_keys?.[name || ""]
    }

    @computed get activeWorkspace() {
        return this.workspaces.get(this.activeWorkspaceId || '')
    }

    @computed get workspacesArray() {
        return Array.from(this.workspaces.values())
    }

    @action selectedActiveWorkspace = (activeWorkspaceId: string) => {
        OpenAPI.HEADERS = {...OpenAPI.HEADERS, "x-workspace-id": activeWorkspaceId}

        this.activeWorkspaceId = activeWorkspaceId
    }

    @action fetch = async (): Promise<Workspace[]> => {
        try {
            const workspaces = await WorkspaceApiService.get()
            runInAction(() => {
                this.workspaces.replace(workspaces.reduce((map, workspace) => {
                    map.set(workspace.id, workspace)
                    return map
                }, observable.map<string, Workspace>()))
                if (this.activeWorkspaceId === undefined && workspaces.length > 0) {
                    this.selectedActiveWorkspace(workspaces[0].id)
                }
                this.isWorkspaceInitialized = true
            })
            return Promise.resolve(workspaces)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    @action create = async (name: string, openai_api_key?: string): Promise<Workspace> => {
        try {
            const workspace = await WorkspaceApiService.create({name, openai_api_key})
            await this.authService.updateToken()
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
                this.selectedActiveWorkspace(workspace.id)
            })
            toaster.success("Workspace created successfully")
            return Promise.resolve(workspace)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    @action update = async (name: string, openai_api_key?: string): Promise<Workspace> => {
        try {
            const workspace = await WorkspaceApiService.update({name, openai_api_key})
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
            })
            toaster.success("Workspace updated successfully")
            return Promise.resolve(workspace)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    @action generateAPIKey = async (name: string): Promise<Workspace> => {
        try {
            const workspace = await WorkspaceApiService.generateApiKey(name)
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
            })
            toaster.success("API Key generated successfully")
            return Promise.resolve(workspace)
        } catch (err) {
            return Promise.reject()
        }
    }

    @action removeApiKey = async (apiKeyId: string): Promise<Workspace> => {
        try {
            const workspace = await WorkspaceApiService.removeApiKey(apiKeyId)
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
            })
            toaster.success("API Key removed successfully")
            return Promise.resolve(workspace)
        } catch (err) {
          return Promise.reject(err)
        }
    }

    @action upsertClientAuthorization = async (request: ClientAuthorizationRequest) => {
        try {
            const workspace = await WorkspaceApiService.upsertClientToken(request)
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
            })
            toaster.success("Client token updated successfully")
            return Promise.resolve(workspace)
        } catch (err) {
          return Promise.reject(err)
        }
    }

    @action removeClientToken = async (audience: string) => {
        try {
            const workspace = await WorkspaceApiService.removeClientToken(audience)
            runInAction(() => {
                this.workspaces.set(workspace.id, workspace)
            })
            toaster.success("Client token removed successfully")
            return Promise.resolve(workspace)
        } catch (err) {
          return Promise.reject(err)
        }
    }
}
