import {observer} from "mobx-react-lite";
import {useRootServices} from "../services";
import React, {useState} from "react";
import * as yup from "yup";
import {getForm} from "../forms";
import {ClientIdTokenType} from "../client";
import {Dialog, Link, Pane, Tab, Tablist, Text, TextareaField, TextInputField} from "evergreen-ui";

const ClientAuthorizationDialog = ({
  show,
  setShow,
  deleteSelection,
  setDeleteSelection
}: {
  show: boolean,
  setShow: (show: boolean) => void,
  deleteSelection?: string,
  setDeleteSelection?: (deleteSelection?: string) => void
}) => {

  const {workspaceService} = useRootServices()

  const authorizationTypes = ["Firebase", "Auth0", "Custom"] as const
  const [authorizationType, setAuthorizationType] = useState<typeof authorizationTypes[number]>("Firebase")

  const schema: Record<typeof authorizationTypes[number], any> = {
    "Custom": yup.object({
      audience: yup.string()
          .test('is-not-empty', 'Audience must not be empty', (value) => value !== '')
          .required('Audience is required'),
      type: yup.string().default("custom"),
      public_key: yup.string().test('is-not-empty', 'Public Key must not be empty', (value) => value !== '')
          .required('Public Key is required')
    }),
    "Auth0": yup.object({
      audience: yup.string()
          .test('is-not-empty', 'Audience must not be empty', (value) => value !== '')
          .required('Client Id is required'),
      type: yup.string().default("custom"),
      public_key: yup.string().test('is-not-empty', 'Public Key must not be empty', (value) => value !== '')
          .required('Public Key is required')
    }),
    "Firebase": yup.object({
      audience: yup.string()
          .test('is-not-empty', 'Project Id must not be empty', (value) => value !== '')
          .required('Project Id is required'),
      type: yup.string().default("firebase")
    })
  }

  const form = getForm(
      {audience: '', type: undefined as unknown as ClientIdTokenType, public_key: undefined},
      schema[authorizationType]
  )({
    onSubmit: (values) =>
        workspaceService.upsertClientAuthorization({
          ...values, type: authorizationType.toLowerCase() as ClientIdTokenType
        }).then(() => setShow(false))
  })

  return (
      <>
        <Dialog
            isShown={show}
            title="Add Client Authorization"
            confirmLabel="Add"
            onCloseComplete={() => {
              setShow(false)
              form.resetForm()
            }}
            onConfirm={() => form.handleSubmit()}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <Tablist>
              {
                authorizationTypes.map(type=>(
                    <Tab
                        isSelected={authorizationType===type}
                        key={type}
                        onSelect={()=>setAuthorizationType(type)}
                    >
                      {type}
                    </Tab>
                ))
              }
            </Tablist>
            {
                authorizationType === "Auth0" && (
                    <Pane display={authorizationType==="Auth0"? "block": "none"}>
                      <TextInputField
                          name="audience"
                          label="Audience"
                          placeholder="Client ID"
                          description={<Text size={300}>
                            The value must match the client ID of the application as defined in your
                            <Link target="_blank" href="https://manage.auth0.com/?_gl=1*1wvjqy4*rollup_ga*MTY1MjcwMzE1MS4xNjczOTYxMjYz*rollup_ga_F1G3E656YZ*MTY3NjExNzc2Mi41LjEuMTY3NjExODQ2Mi42MC4wLjA.#/applications"> Application's Settings </Link>
                            in the Client ID field.
                          </Text>}
                      />
                      <TextareaField
                          name="public_key"
                          label="Public Key"
                      />
                    </Pane>
                )
            }
            {
                authorizationType === "Firebase" && (
                    <Pane display={authorizationType==="Firebase"? "block": "none"}>
                      <TextInputField
                          name="audience"
                          label="Audience"
                          placeholder="Project Id"
                          description="Go to Firebase Project Settings > General > Project ID field"
                          value={form.values.audience}
                          onChange={form.handleChange}
                          validationMessage={form.touched.audience && form.errors.audience}
                      />
                    </Pane>
                )
            }
            {
                authorizationType === "Custom" && (
                    <Pane>
                      <TextInputField
                          name="audience"
                          label="Audience"
                          placeholder="audience"
                          description={<Text size={300}>
                            Use the value of JWT aud (Audience) Claim.
                            <Link target="_blank" href="https://www.rfc-editor.org/rfc/rfc7519#section-4.1.3"> Reference</Link>
                          </Text>}
                          value={form.values.audience}
                          onChange={form.handleChange}
                          validationMessage={form.touched.audience && form.errors.audience}
                      />
                      <TextareaField
                          name="public_key"
                          label="Public Key"
                          description={<Text size={300}>
                            RS256 public key which is used to validate signature of the jwt token
                          </Text>}
                          value={form.values.public_key}
                          onChange={form.handleChange}
                          validationMessage={form.touched.public_key && form.errors.public_key}
                      />
                    </Pane>
                )
            }
          </Pane>
        </Dialog>
        <Dialog
            isShown={deleteSelection !== undefined}
            title="Delete Client Authorization"
            intent="danger"
            confirmLabel="Delete"
            onCloseComplete={() => setDeleteSelection && setDeleteSelection(undefined)}
            onConfirm={() => deleteSelection && workspaceService.removeClientToken(deleteSelection)
                .then(() => setDeleteSelection && setDeleteSelection(undefined))}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <Text size={400}>
              This Token Access will immediately be disabled. API requests made using
              this key will be rejected, which could cause any systems still
              depending on it to break. Once revoked, you'll no longer be able to
              view or modify.
            </Text>
            <Pane
                display="flex"
                border="default"
                padding={10}
                borderRadius={5}
                justifyContent="center"
                marginBottom={50}
            >
              <Text>{deleteSelection}</Text>
            </Pane>
          </Pane>
        </Dialog>
      </>
  )
}

export default observer(ClientAuthorizationDialog)