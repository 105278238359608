import {observer} from "mobx-react-lite";
import {useRootServices} from "../services";
import React from "react";
import {useWorkspaceFormsWithOpenAIKey} from "../forms";
import {Dialog, Link, Pane, Text, TextInputField} from "evergreen-ui";


const OpenAIAPIKeyDialog = ({show, setShow, onCanceled}: {
  show: boolean, setShow: (show: boolean) => void, onCanceled?: () => void
}) => {

  const { workspaceService } = useRootServices()

  const forum = useWorkspaceFormsWithOpenAIKey({
    onSubmit: (values) => {
      workspaceService.update(values.name, values.openai_api_key || undefined);
    },
    initialValues: {
      name: workspaceService?.activeWorkspace?.name || "",
      openai_api_key: workspaceService?.activeWorkspace?.openai_api_key?.key || ""
    },
  })

  return (
      <>
        <Dialog
            isShown={show}
            title="OpenAI API Key"
            confirmLabel="Save"
            isConfirmDisabled={!forum.isValid}
            onConfirm={() => forum.isValid && forum.handleSubmit()}
            onCancel={() => {
              setShow(false)
              onCanceled && onCanceled()
            }}
        >
          <Pane display="flex" flexDirection="column" gap={25}>
            <TextInputField
                name="openai_api_key"
                label="OpenAI API Key is missing"
                type="password"
                hint={
                  <Text>
                    click{" "}
                    <Link href="https://beta.openai.com/account/api-keys">
                      here
                    </Link>{" "}
                    to get your API key.
                  </Text>
                }
                placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                value={forum.values.openai_api_key}
                onChange={forum.handleChange}
                validationMessage={forum.touched.openai_api_key && forum.errors.openai_api_key}
            />
          </Pane>
        </Dialog>
      </>
  );
}


export default observer(OpenAIAPIKeyDialog);