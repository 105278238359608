import React, {useCallback, useEffect, useState} from "react";
import {Button, DeleteIcon, Heading, Pane, Table, WrenchIcon,} from "evergreen-ui";
import {useRootServices} from "../../services";
import {observer} from "mobx-react-lite";
import {limitToFirst, onValue, orderByKey, query, startAfter} from "firebase/database";
import {Dataset} from "../../services/models.service";
import InfiniteScroll from "react-infinite-scroll-component";
import {Rings} from "react-loader-spinner";

const ModelDatasets = () => {
  const { modelsService, workspaceService: {activeWorkspaceId}, templateService: {getParams} } = useRootServices()

  const [items, setItems] = useState<Dataset[]>([]);
  const [lastKey, setLastKey] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(true);

  const params = modelsService.activeModel? getParams(modelsService.activeModel.template_id) : undefined

  const getData = useCallback(() => {
    if (!modelsService.activeModel) return setHasMore(false)

    let queryItem = query(modelsService.datasetRef, orderByKey());
    if (lastKey) queryItem = query(queryItem, startAfter(lastKey));
    queryItem = query(queryItem, limitToFirst(50));

    const unsubscribe = onValue(queryItem, (snapshot) => {
      let data = snapshot.val();
      if (data) {
        let keys = Object.keys(data) as string[];
        let values = Object.values(data) as Dataset[];
        setItems(items.concat(values));
        setLastKey(keys[keys.length - 1]);
      } else {
        setHasMore(false);
      }
    }, {onlyOnce: true});

    return () => unsubscribe();
  }, [modelsService.activeModel, activeWorkspaceId, lastKey, items])

  useEffect(() => {
    if (!modelsService.activeModel) return

    setItems([]);
    setLastKey(undefined);
    setHasMore(true);
    getData();
  }, [modelsService.activeModel, activeWorkspaceId])

  const Loader = () => (
      <Pane display="flex" justifyContent="center" alignItems="center" height={100}>
        <Rings
            height="80"
            width="80"
            color="black"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
        />
      </Pane>
  )

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="space-between" alignItems="center">
        <Heading size={800}>{modelsService.activeModel?.name}</Heading>
        <Pane display="flex" gap={5}>
          <Button
            appearance="primary"
            iconAfter={WrenchIcon}
            onClick={() => modelsService.fineTuneModel()}
          >
            FineTune
          </Button>
        </Pane>
      </Pane>
      <Pane padding={16}>
        <Table border="default" borderRadius={5}>
          <Table.Head>
            {
              params?.map((param) => (
                  <Table.TextCell>{param}</Table.TextCell>
              ))
            }
            <Table.TextCell>Output</Table.TextCell>
          </Table.Head>
          <InfiniteScroll
              dataLength={items.length}
              next={getData}
              hasMore={hasMore}
              loader={<Loader/>}
              height="calc(100vh - 300px)"
          >
            {items.map((item, index) => (
                <Table.Row key={index} isSelectable>
                  {
                    params?.map((param) => (
                        <Table.TextCell>{item?.params?.[param]?? "N/A"}</Table.TextCell>
                    ))
                  }
                  <Table.TextCell>{item.completion}</Table.TextCell>
                </Table.Row>
            ))}
          </InfiniteScroll>
        </Table>
      </Pane>
    </Pane>
  );
};

export default observer(ModelDatasets);
