/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Template } from '../models/Template';
import type { TemplateRequest } from '../models/TemplateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplatesService {

    /**
     * Get
     * @param xWorkspaceId
     * @param authorization
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static get(
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Array<Template>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/templates/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert
     * @param requestBody
     * @param xWorkspaceId
     * @param authorization
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static upsert(
        requestBody: TemplateRequest,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/templates/',
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param templateId
     * @param xWorkspaceId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static delete(
        templateId: string,
        xWorkspaceId?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            headers: {
                'x-workspace-id': xWorkspaceId,
                'authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
