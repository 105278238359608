import {
    observable,
    action,
    makeAutoObservable,
    runInAction,
    computed, autorun,
} from 'mobx'
import {TemplatesService, Template, OpenAPI} from "../client";
import WorkspaceService from "./workspace.service";
import AuthService from "./auth.service";
import Mustache from "mustache";
import {toaster} from "evergreen-ui";


export default class TemplateService {
    @observable templates = observable.map<string, Template>()
    @observable activeWorkspaceId?: string

    constructor(private authService: AuthService, private workspaceService: WorkspaceService) {
        makeAutoObservable(this)

        autorun(() => this.authService.isLoggedIn && this.workspaceService.activeWorkspaceId && this.fetch())
    }

    @computed get templatesArray(): Template[] {
        return Array.from(this.templates.values())
    }

    public getParams = (templateId: string): string[] => {
        const template = this.templates.get(templateId)
        if (!template) return []
        return this.getParamsFromData(template.template)
    }

    public getParamsFromData = (template: string): string[] => {
        try {
            const params = Mustache.parse(template).filter(x=>x[0]==="name").map(x=>x[1])
            return Array.from(new Set(params))
        } catch (err) {
            return []
        }
    }

    public render = (templateId: string, params: {[key: string]: string}): string | undefined => {
        const template = this.templates.get(templateId)
        if (!template) return undefined

        return Mustache.render(template.template, params)
    }

    @action fetch = async () => {
        try {
            const templates = await TemplatesService.get()
            runInAction(() => {
                this.templates.replace(templates.reduce((map, template) => {
                    map.set(template.id, template)
                    return map
                }, observable.map<string, Template>()))
            })
        } catch (err) {
            
        }
    }

    @action upsert = async (name: string, templateData: string, id?: string) => {
        try {
            const template = await TemplatesService.upsert({
                id,
                name,
                template: templateData,
            })
            runInAction(() => {
                this.templates.set(template.id, template)
            })
            toaster.success("Template saved")
            return Promise.resolve(template)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    @action delete = async (template_id: string) => {
        try {
            await TemplatesService.delete(template_id)
            runInAction(() => {
                this.templates.delete(template_id)
            })
            toaster.success("Template deleted")
        } catch (err) {
            
        }
    }
}
