import * as yup from "yup";


export default yup.object({
  name: yup.string()
      .test('is-not-empty', 'Model name must not be empty', (value) => value !== '')
      .required('Model name is required'),
  base_model_id: yup.string()
      .test('is-not-empty', 'Model name must not be empty', (value) => value !== '')
      .required('Base Model is required'),
  template_id: yup.string()
      .test('is-not-empty', 'Model name must not be empty', (value) => value !== '')
      .required('Template is required'),
  id: yup.string(),
})