import React, {useCallback, useEffect, useState} from "react";
import {Button, Heading, Pane, Table, Tooltip} from "evergreen-ui";
import InfiniteScroll from "react-infinite-scroll-component"
import {useRootServices} from "../../services";
import {observer} from "mobx-react-lite";
import {database} from "../../utils/firebase";
import { ref, orderByKey, startAfter, query, limitToFirst, onValue } from "firebase/database";
import {Rings} from "react-loader-spinner";


type APIEvent = {
  userId: string,
  workspaceId: string,
  request: any,
  response: any,
}


const APIEvents = () => {
  const { apiService: {activeApi}, workspaceService: {activeWorkspaceId}, templateService: {getParams} } = useRootServices()

  const [items, setItems] = useState<APIEvent[]>([]);
  const [lastKey, setLastKey] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(true);

  const params = activeApi? getParams(activeApi.template_id) : undefined

  const getData = useCallback(() => {
    if (!activeApi) return setHasMore(false)

    const itemRef = ref(database, `analytics/${activeWorkspaceId}/apiEvents/${activeApi.id}`)
    let queryItem = query(itemRef, orderByKey());
    if (lastKey) queryItem = query(queryItem, startAfter(lastKey));
    queryItem = query(queryItem, limitToFirst(50));

    const unsubscribe = onValue(queryItem, (snapshot) => {
      let data = snapshot.val();
      if (data) {
        let keys = Object.keys(data) as string[];
        let values = Object.values(data) as APIEvent[];
        setItems(items.concat(values));
        setLastKey(keys[keys.length - 1]);
      } else {
        setHasMore(false);
      }
    }, {onlyOnce: true});

    return () => unsubscribe();
  }, [activeApi?.id, activeWorkspaceId, lastKey, items])

  useEffect(() => {
    if (!activeApi) return
    setItems([]);
    setLastKey(undefined);
    setHasMore(true);
    getData();
  }, [activeApi?.id, activeWorkspaceId])

  const Loader = () => (
    <Pane display="flex" justifyContent="center" alignItems="center" height={100}>
      <Rings
          height="80"
          width="80"
          color="black"
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
      />
    </Pane>
  )

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="space-between" alignItems="center">
        <Heading size={800}>{activeApi?.name}</Heading>
        {/*<Pane display="flex" gap={5}>*/}
        {/*  <Button*/}
        {/*    appearance="primary"*/}
        {/*    iconAfter={WrenchIcon}*/}
        {/*    onClick={() => modelsService.fineTuneModel()}*/}
        {/*  >*/}
        {/*    FineTune*/}
        {/*  </Button>*/}
        {/*</Pane>*/}
      </Pane>
      <Pane padding={16}>
        <Table border="default" borderRadius={5}>
          <Table.Head>
            <Table.TextCell>User Id</Table.TextCell>
            {
              params?.map((param) => (
                <Table.TextCell>{param}</Table.TextCell>
              ))
            }
            <Table.TextCell>Response</Table.TextCell>
          </Table.Head>
          <InfiniteScroll
            dataLength={items.length}
            next={getData}
            hasMore={hasMore}
            loader={<Loader/>}
            height="calc(100vh - 300px)"
          >
            {items.map((item, index) => (
                <Table.Row key={index} isSelectable>
                  <Table.TextCell>{item.userId}</Table.TextCell>
                  {
                    params?.map((param) => (
                        <Table.TextCell key={param}>{item.request?.params?.[param]?? "N/A"}</Table.TextCell>
                    ))
                  }
                  <Table.TextCell>{JSON.stringify(item.response)}</Table.TextCell>
                </Table.Row>
            ))}
          </InfiniteScroll>
        </Table>
      </Pane>
    </Pane>
  );
};

export default observer(APIEvents);
