import React, {useState} from "react";
import {
  Autocomplete,
  Button,
  DatabaseIcon,
  DeleteIcon,
  Dialog,
  GitBranchIcon,
  Heading,
  Pane,
  PlusIcon,
  Spinner,
  StatusIndicator,
  Strong,
  Text,
  TextInputField,
  Tooltip,
  WrenchIcon,
} from "evergreen-ui";
import {useNavigate} from "react-router-dom";
import {useRootServices} from "../../services";
import {observer} from 'mobx-react-lite';
import {timeAgoString} from "../../utils";
import {FineTuneEvent} from "../../services/models.service";
import {useModelForms} from "../../forms";


const Models = () => {
  const [saveModelDialog, setSaveModelDialog] = useState<boolean>();
  const [deleteItem, setDeleteItem] = useState<string | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>("");

  const navigate = useNavigate();

  const { modelsService, templateService } = useRootServices()

  const formik = useModelForms({
    onSubmit: (values) => {
      modelsService.upsert(
          values.name,
          values.base_model_id,
          values.template_id,
          values.id || undefined,
      ).then(() => setSaveModelDialog(false))
    },
  })

  const templateOptions = templateService.templatesArray
      .map(template=>({...template, toString: () => template.name}))

  const baseModelOptions = modelsService.allBaseModels
      .filter(({canBeFineTuned}) => canBeFineTuned)
      .map(models=>({...models, toString: () => models.name}))

  const checkFineTuneStatus = (fineTuneEvent: FineTuneEvent) => (
      fineTuneEvent.task_type === "poll_model_request" && fineTuneEvent.state === "complete"
  )? "Complete": fineTuneEvent.state === "failed"? "Failed": "Running"

  return (
    <Pane width="100%">
      <Pane display="flex" padding={16} justifyContent="right">
        <Pane marginLeft={20}>
          <Button
            appearance="primary"
            iconAfter={PlusIcon}
            onClick={() => {
              formik.resetForm()
              setSaveModelDialog(true)
            }}
          >
            New Model
          </Button>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        padding={16}
        flexDirection="column"
        alignItems="center"
        gap={10}
      >
        {
          modelsService.modelsArray.map((model) => {
            const template = templateService.templates.get(model.template_id)
            const finetuned = !!model.openai_model_name
            const fineTuneEvent = modelsService.fineTuneEvents.get(model.id)
            const fineTuneStatus = finetuned? "Complete": fineTuneEvent? checkFineTuneStatus(fineTuneEvent): "Not Started"

            return (
                <Pane
                    key={model.id}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    border="default"
                    padding={15}
                    borderRadius={5}
                    alignItems="center"
                    onClick={() => {
                      if (fineTuneStatus === "Running") return
                      modelsService.setActiveModel(model.id)
                      if (!finetuned) navigate(`/models/fine-tune-model?model=${model.id}`)
                    }}
                >
                  <Pane width={250}>
                    <Heading size={500}>{model.name}<Text> • {model.base_model_id}</Text></Heading>
                    <Text>Added {timeAgoString(model.created_at as string)}</Text>
                  </Pane>
                  <Pane display="flex" width={250} flexDirection="column">
                    <Strong>Template</Strong>
                    <Text>{template? template.name: "NA"}</Text>
                  </Pane>
                  <Pane display="flex" width={400} flexDirection="column">
                    <Strong>FineTune</Strong>
                    {
                      fineTuneStatus === "Running"? (
                        <Tooltip content={fineTuneEvent?.message?? "Running"}>
                          <Pane display="flex" alignItems="center">
                            <Spinner size={16} marginRight={10} color="blue"/>
                            <Text>{fineTuneEvent?.title?? "Running"}</Text>
                          </Pane>
                        </Tooltip>
                      ):
                      <StatusIndicator color={
                        fineTuneStatus === "Complete" ? "success" :
                        fineTuneStatus === "Not Started" ? "neutral" :
                        fineTuneStatus === "Failed" ? "danger" : "warning"
                      }>
                        {fineTuneStatus}
                      </StatusIndicator>
                    }
                  </Pane>
                  <Pane width={80} display="flex" gap={15} justifyContent="right">
                    <Tooltip content="New Model from Base Model">
                      <GitBranchIcon
                          display={finetuned? "block": "none"}
                          onClick={() => {}}
                          color="gray700"
                      />
                    </Tooltip>
                    <Tooltip content="View Dataset">
                      <DatabaseIcon
                          display={!finetuned? "block": "none"}
                          onClick={(e) => {
                            modelsService.setActiveModel(model.id)
                            navigate("/models/dataset")
                            e.stopPropagation()
                          }}
                          color="gray700"
                      />
                    </Tooltip>
                    {
                      !finetuned && <Tooltip content="FineTune Model">
                        <WrenchIcon color="gray700"/>
                      </Tooltip>
                    }
                    <Tooltip content="Delete">
                      <DeleteIcon color="red500" onClick={(event:any) => {
                        setDeleteItem(model.id)
                        event.stopPropagation()
                      }}/>
                    </Tooltip>
                  </Pane>
                </Pane>
            )
          })
        }
      </Pane>
      <Dialog
        isShown={saveModelDialog}
        title="Finetune Model"
        confirmLabel="Create"
        onCloseComplete={() => setSaveModelDialog(false)}
        isConfirmDisabled={!formik.isValid}
        onConfirm={() => formik.handleSubmit()}
      >
        <TextInputField
          label="Name"
          name="name"
          placeholder="New Model"
          value={formik.values.name}
          onChange={formik.handleChange}
          validationMessage={formik.touched.name && formik.errors.name}
        />
        <Autocomplete
          title="Templates"
          onChange={changedItem => formik.setFieldValue('template_id', changedItem.id)}
          items={templateOptions}
        >
          {props => {
            const { getInputProps, getRef, openMenu } = props
            return (
                <TextInputField
                    label="Select Template"
                    placeholder="Not Selected"
                    validationMessage={formik.touched.template_id && formik.errors.template_id}
                    ref={getRef}
                    {...getInputProps({
                      onFocus: () => openMenu()
                    })}
                />
            )
          }}
        </Autocomplete>
        <Autocomplete
          title="Models"
          onChange={changedItem => formik.setFieldValue('base_model_id', changedItem.id)}
          items={baseModelOptions}
        >
          {props => {
            const { getInputProps, getRef, openMenu } = props
            return (
                <TextInputField
                    label="Select Base Model"
                    placeholder="Not Selected"
                    validationMessage={formik.touched.base_model_id && formik.errors.base_model_id}
                    ref={getRef}
                    {...getInputProps({
                      onFocus: () => openMenu()
                    })}
                />
            )
          }}
        </Autocomplete>
      </Dialog>
      <Dialog
          isShown={!!deleteItem}
          title={`Delete Confirmation`}
          intent="danger"
          confirmLabel="Delete"
          onCloseComplete={() => {
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
          isConfirmDisabled={deleteConfirmation !== modelsService.models.get(deleteItem||"")?.name}
          onConfirm={() => {
            modelsService.delete(deleteItem as string)
            setDeleteItem(undefined)
            setDeleteConfirmation("")
          }}
      >
        <TextInputField
          label={`Are Your Sure you want to Delete ${modelsService.models.get(deleteItem || "")?.name}?`}
          description="Please type in full model name to confirm deletion"
          placeholder={modelsService.models.get(deleteItem||"")?.name}
          value={deleteConfirmation}
          onChange={(e: any)=>setDeleteConfirmation(e.target.value)}
        />
      </Dialog>
    </Pane>
  );
};

export default observer(Models);
