import * as yup from "yup";

export default yup.object({
    name: yup.string()
        .test('is-not-empty', 'Name name must not be empty', (value) => value !== '')
        .required('Name is required'),
    company: yup.string()
        .test('is-not-empty', 'Company name must not be empty', (value) => value !== '')
        .required('Company is required'),
})
